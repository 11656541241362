export class CalculationDto {
	nr?: number;
	description?: string;
	status?: number;
	presentValue?: number;
	presentValueOrigin?: number;
	finalPayment?: number;
	listPrice?: number;
	discount?: number;
	newPurchaseCosts?: number;
	specialPayment?: number;
	presentValueMargin?: number;
	term?: number;
	interest?: number;
	residualValue?: number;
	installment?: number;
	paymentStep?: number;
	paymentMethod?: boolean;
	dissolveBy?: string;
	rentAssessmentBase?: number;
	calculationTypeDomain?: string;
	calculationTypeNr?: number;
}
