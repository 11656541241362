export class CalculationTypeDto {
	nr?: number;
	topic?: string;
	domain?: string;
	domainTopic?: string;

	specialPaymentMin?: number;
	specialPaymentMax?: number;
	specialPaymentDefault?: number;
	specialPaymentDefaultInPercent?: boolean;
	specialPaymentReference?: number;
	specialPaymentType?: number;

	presentValueMarginMin?: number;
	presentValueMarginMax?: number;
	presentValueMarginDefault?: number;
	presentValueMarginDefaultInPercent?: boolean;
	presentValueMarginReference?: number;
	presentValueMarginType?: number;

	termMin?: number;
	termMax?: number;
	termDefault?: number;
	termType?: number;

	interestMin?: number;
	interestMax?: number;
	interestDefault?: number;
	interestDefaultInPercent?: boolean;
	interestReference?: number;
	interestType?: number;

	residualValueMin?: number;
	residualValueMax?: number;
	residualValueDefault?: number;
	residualValueDefaultInPercent?: boolean;
	residualValueReference?: number;
	residualValueType?: number;

	installmentMin?: number;
	installmentMax?: number;
	installmentDefault?: number;
	installmentDefaultInPercent?: boolean;
	installmentReference?: number;
	installmentType?: number;

	paymentStepDefault?: number;
	paymentStepType?: number;

	paymentMethodDefault?: boolean;
	paymentMethodType?: number;
	
}