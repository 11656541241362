export enum Reference {
	None = -1, // Always for interest
	NewPurchaseCosts = 0, // Default for special payment
	NewPurchaseCostsMinusSpecialPayment = 1, // Default for ...
	ListPrice = 4, // Always for discount, default for residual value
	ListPriceMinusDiscount = 6, // Always for new purchase costs
	ListPriceMinusDiscountMinusSpecialPayment = 7, 
	RentAssessmentBase = 10, // Default for installment
	NewPurchaseCostsPlusDiscount = 20, // Always for list price
	ListPriceMinusNewPurchaseCosts = 21
}
