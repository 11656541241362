import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonText, IonToast, isPlatform} from '@ionic/react';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import { convertRawValueToDecimalNumber } from '../../utils/calculatorUtils';
import './MoneyOrPercentageInput.css';
import { chevronForward } from 'ionicons/icons';
import { Field } from '../../enums/Field';

interface IMoneyOrPercentageInputProps { 
	id: Field;
	label: string;
	raw: string;
	rawChanged: boolean;
	inEuros: boolean;
	isFocussed: boolean;
	euroOrPercentButtonDisabled: boolean;
	placeholder: string;
	inputMode: any;
	enterKeyHint: any;
	tabIndex: number;
	disabled: boolean;
	dissvolveBy: boolean;
	onBlur: (id: Field, raw: string) => void;
	onEuroOrPercentButtonClicked: (id: Field) => void;
	focusPreviousOrNextCalculationInput: (id: Field, e: KeyboardEvent) => void;
	resetRawChanged: (id: Field) => void;
	resetFocus: (id: Field) => void;
}

const MoneyOrPercentageInput: React.FC<IMoneyOrPercentageInputProps> = (props) => {

	const itemElement = useRef<any>(null);
	const labelIconElement = useRef<any>(null);
	const inputElement = useRef<any>(null);
	const { isOpen } = useKeyboardState();

	const [raw, setRaw] = useState<string>(props.raw);
	const [dissvolveBy, setDissvolveBy] = useState<boolean>(props.dissvolveBy);
	const [showDisabledMessage, setShowDisabledMessage] = useState(false);
	const [showDissolveByMessage, setShowDissolveByMessage] = useState(false);

	useEffect(() => {
		if (props.isFocussed) {
			inputElement.current.setFocus();
			props.resetFocus(props.id);
		}
	})

	useEffect(() => {
		if (props.rawChanged) {
			setRaw(props.raw);
			props.resetRawChanged(props.id);
		}
	}, [props])

	useEffect(() => {
		setDissvolveBy(props.dissvolveBy);
		// if (props.dissvolveBy) {
		// 	itemElement.current.classList.add('input-item-dissolve-by');
		// 	labelIconElement.current.classList.remove('ion-hide');
		// } else {
		// 	itemElement.current.classList.remove('input-item-dissolve-by');
		// 	labelIconElement.current.classList.add('ion-hide');
		// }
	}, [props.dissvolveBy, dissvolveBy])

	const onChange = (value: any) => {
    if (!isNaN(value.replace(/\./g, '').replace(/,/g, '.'))) {
			if (!props.inEuros) {
				const numberValue: number = convertRawValueToDecimalNumber(value, 3);
				if (numberValue < 0) {
					setRaw('');
					setRaw('0');
				} else if (numberValue > 100) {
					setRaw('');
					setRaw('100');
				} else {
					setRaw(value);
				}
			} else {
				setRaw(value);
			}
    } else {
      setRaw('');
    }
	}

	const onInput = (value: any) => {
		if (typeof value !== 'undefined' && value !== null) {
			const valueParts = value.toString().split('.');
			if (isPlatform('android') && valueParts.length === 2 && !value.toString().includes(',') && valueParts[valueParts.length - 1].length < 3) {
				setRaw(value.toString().replace(/\./g, ','));
			}
		}
	}

	const onFocus = (e: CustomEvent) => {
    const { srcElement } = e;
    if (isOpen && srcElement instanceof HTMLInputElement) {
      // srcElement.style.setProperty(
      //   'transform',
      //   `translate3d(0, ${keyboardHeight}px, 0)`
      // );
    }
	}
	
	const disableDefaultTab = async (e: KeyboardEvent) => {
    if ((e.key === 'Tab' && !e.shiftKey) || (e.key === 'Tab' && e.shiftKey)) {
      e.preventDefault();
    }
	}

	const onItemClick = async (e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			inputElement.current.setFocus();
		}
		if (props.disabled) {
			// setShowDisabledMessage(true);
		}
		if (props.dissvolveBy) {
			// setShowDissolveByMessage(true);
		}
	}

	const onInputClick = async (e: React.MouseEvent) => {
		e.persist();
		let nativeInput = await inputElement.current.getInputElement();
		if (!props.disabled && !props.dissvolveBy) {
			nativeInput.select();
		}
	}


  return (
		<React.Fragment>
			<IonItem ref={itemElement} key={props.id} class="input-item" lines="full" onClick={e => onItemClick(e)}>
				<IonLabel position="fixed" color={dissvolveBy ? 'primary': ''}>
					<IonIcon ref={labelIconElement} color="primary" icon={chevronForward} class="ion-hide"></IonIcon>
					<span>{props.label}</span>
				</IonLabel>
				<IonInput ref={inputElement} class="ion-text-end" /* class={dissvolveBy ? 'ion-hide' : ''} */
					name={props.id} 	
					id={props.id} 
					value={raw} 
					autocomplete="off"
					min={props.inEuros ? undefined : '0'}
					max={props.inEuros ? undefined : '100'}
					placeholder={props.placeholder}
					inputmode={props.inputMode}
					enterkeyhint={props.enterKeyHint}
					tabIndex={props.tabIndex}
					clearInput={false}
					disabled={props.disabled}
					readonly={props.disabled || dissvolveBy}
					color={dissvolveBy ? 'primary': ''}
					onClick={e => onInputClick(e)}
					onIonChange={e => onChange(e.detail.value)}
					onIonBlur={() => props.onBlur(props.id, raw)}
					onIonFocus={e => onFocus(e)}
					onInput={e => onInput(e.currentTarget.value)}
					onKeyUp={e => props.focusPreviousOrNextCalculationInput(props.id, e)} 
					onKeyDown={e => disableDefaultTab(e)}
				></IonInput>
				<IonText color="primary" class="ion-hide" /* class={dissvolveBy ? '': 'ion-hide'} */>
						wird aufgelöst
					</IonText>
				<IonButton class="ion-margin-start" /* class={dissvolveBy ? 'ion-hide' : 'ion-margin-start'} */
					color="medium" 
					disabled={props.euroOrPercentButtonDisabled || props.disabled}
					onClick={() => props.onEuroOrPercentButtonClicked(props.id)}>{props.inEuros? '€' : '%'}</IonButton>
			</IonItem>
			<IonToast
				isOpen={showDisabledMessage}
				onDidDismiss={() => setShowDisabledMessage(false)}
				header="Fixer Wert"
				message={props.label + ' ist fix und kann nicht geändert werden.'}
				color="primary"
				position="top"
				duration={3000}
			/>
			<IonToast
				isOpen={showDissolveByMessage}
				onDidDismiss={() => setShowDissolveByMessage(false)}
				header={props.label + ' wird aufgelöst'}
				message={'Nach dem Wert ' + props.label + ' wird aufgelöst.'}
				color="primary"
				position="top"
				duration={3000}
			/>
		</React.Fragment>
  );
};

export default MoneyOrPercentageInput;
