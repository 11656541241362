import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { IonIcon, IonInput, IonItem, IonLabel, IonRange, IonText, IonToast} from '@ionic/react';
import { useKeyboardState } from '@ionic/react-hooks/keyboard';
import './TermInput.css';
import { chevronForward } from 'ionicons/icons';
import { Field } from '../../enums/Field';

interface ITermInputProps { 
	id: Field;
	label: string;
	min: number;
	max: number;
	default: number;
	raw: number;
	rawChanged: boolean;
	isFocussed: boolean;
	placeholder: string;
	inputMode: any;
	enterKeyHint: any;
	tabIndex: number;
	disabled: boolean;
	dissvolveBy: boolean;
	onBlur: (id: Field, raw: number) => void;
	focusPreviousOrNextCalculationInput: (id: Field, e: KeyboardEvent) => void;
	resetRawChanged: (id: Field) => void;
	resetFocus: (id: Field) => void;
}

const TermInput: React.FC<ITermInputProps> = (props) => {

	const itemElement = useRef<any>(null);
	const labelIconElement = useRef<any>(null);
	const inputElement = useRef<any>(null);
	const { isOpen } = useKeyboardState();

	const [raw, setRaw] = useState<number>(props.default);
	const [dissvolveBy, setDissvolveBy] = useState<boolean>(props.dissvolveBy);
	const [showDisabledMessage, setShowDisabledMessage] = useState(false);
	const [showDissolveByMessage, setShowDissolveByMessage] = useState(false);

	useEffect(() => {
		if (props.isFocussed) {
			inputElement.current.setFocus();
			props.resetFocus(props.id);
		}
	})

	useEffect(() => {
		if (props.rawChanged) {
			setRaw(props.raw);
			props.resetRawChanged(props.id);
		}
	}, [props])

	useEffect(() => {
		setDissvolveBy(props.dissvolveBy);
		// if (props.dissvolveBy) {
		// 	itemElement.current.classList.add('input-item-dissolve-by');
		// 	labelIconElement.current.classList.remove('ion-hide');
		// } else {
		// 	itemElement.current.classList.remove('input-item-dissolve-by');
		// 	labelIconElement.current.classList.add('ion-hide');
		// }
	}, [props.dissvolveBy, dissvolveBy])

	const onChange = (value: any) => {
		if (value === '') {
		} else if (!isNaN(value)) {
			let numberValue: number;
			if (typeof value === 'string') {
				numberValue = parseInt(value);
			} else {
				numberValue = value;
			}
			if (numberValue < props.min) {
				setRaw(props.min);
			} else if (numberValue > props.max) {
				setRaw(0);
				setRaw(props.max);
			} else {
				setRaw(value);
			}
    } else {
      setRaw(props.default);
    }
	}

	const onFocus = (e: CustomEvent) => {
    const { srcElement } = e;
    if (isOpen && srcElement instanceof HTMLInputElement) {
      // srcElement.style.setProperty(
      //   'transform',
      //   `translate3d(0, ${keyboardHeight}px, 0)`
      // );
    }
	}
	
	const disableDefaultTab = async (e: KeyboardEvent) => {
    if ((e.key === 'Tab' && !e.shiftKey) || (e.key === 'Tab' && e.shiftKey)) {
      e.preventDefault();
    }
	}

	const onItemClick = async (e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			inputElement.current.setFocus();
		}
		if (props.disabled) {
			// setShowDisabledMessage(true);
		}
		if (props.dissvolveBy) {
			// setShowDissolveByMessage(true);
		}
	}

  return (
		<React.Fragment>
			<IonItem ref={itemElement} key={props.id} class="input-item" lines="none" onClick={e => onItemClick(e)}>
				<IonLabel position="fixed" color={dissvolveBy ? 'primary': ''}>
					<IonIcon ref={labelIconElement} color="primary" icon={chevronForward} class="ion-hide"></IonIcon>
					<span>{props.label}</span>
				</IonLabel>
				<IonInput ref={inputElement} class="ion-text-end" /* class={dissvolveBy ? 'ion-hide' : ''} */
					name={props.id} 	
					id={props.id} 
					value={raw} 
					type="number"
					autocomplete="off"
					min={props.min.toString()}
					max={props.max.toString()}
					placeholder={props.placeholder}
					inputmode={props.inputMode}
					enterkeyhint={props.enterKeyHint}
					tabIndex={props.tabIndex}
					readonly={props.disabled || dissvolveBy}
					color={dissvolveBy ? 'primary': ''}
					onIonChange={e => onChange(e.detail.value)}
					onIonBlur={() => props.onBlur(props.id, raw)}
					onIonFocus={e => onFocus(e)}
					onKeyUp={e => props.focusPreviousOrNextCalculationInput(props.id, e)} 
					onKeyDown={e => disableDefaultTab(e)}
				></IonInput>
				<IonText color="primary" class="ion-hide" /* class={dissvolveBy ? '': 'ion-hide'} */>
					wird aufgelöst
				</IonText>
				<IonLabel slot="end" class="item-label-term" color={dissvolveBy ? 'primary': ''} /* class={dissvolveBy ? 'ion-hide' : ''} */>Monat(e)</IonLabel>
			</IonItem>
			<IonItem key={props.id + '-range'} class="input-item" lines="full" onClick={e => onItemClick(e)}>
				<IonRange value={raw} 
					min={props.min}
					max={props.max}
					color="primary"
					disabled={props.disabled || dissvolveBy}
					onIonChange={e => onChange(e.detail.value)}
					onIonBlur={() => props.onBlur(props.id, raw)}
					onIonFocus={e => onFocus(e)}
					onKeyDown={e => disableDefaultTab(e)}>
					<IonLabel slot="start">{props.min}</IonLabel>
					<IonLabel slot="end">{props.max}</IonLabel>
				</IonRange>
			</IonItem>
			<IonToast
				isOpen={showDisabledMessage}
				onDidDismiss={() => setShowDisabledMessage(false)}
				header="Fixer Wert"
				message={props.label + ' ist fix und kann nicht geändert werden.'}
				color="medium"
				position="top"
				duration={3000}
			/>
			<IonToast
				isOpen={showDissolveByMessage}
				onDidDismiss={() => setShowDissolveByMessage(false)}
				header={props.label + ' wird aufgelöst'}
				message={'Nach dem Wert ' + props.label + ' wird aufgelöst.'}
				color="primary"
				position="top"
				duration={3000}
			/>
		</React.Fragment>
  );
};

export default TermInput;
