import { PaymentMethod } from '../enums/PaymentMethod';
import { PaymentStep } from '../enums/PaymentStep';

export const getPaymentStepLabel = (paymentStep: PaymentStep): string => {
	let paymentStepLabel: string = '';
	switch (paymentStep) {
		case PaymentStep.Monthly:
			paymentStepLabel = 'monatlich';
			break;
		case PaymentStep.Quarterly:
			paymentStepLabel = 'vierteljährlich';
			break;
		case PaymentStep.ThreeTimesAYear:
			paymentStepLabel = 'dreimal jährlich';
			break;
		case PaymentStep.HalfYearly:
			paymentStepLabel = 'halbjährlich';
			break;
		case PaymentStep.Yearly:
			paymentStepLabel = 'jährlich';
			break;
		default:
			break;
	}
	return paymentStepLabel;
}

export const getPaymentMethodLabel = (paymentMethod: PaymentMethod): string => {
	if (paymentMethod === PaymentMethod.InAdvance) {
		return 'vorschüssig';
	} else {
		return 'nachschüssig';
	}
}
