// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import './CalculationTypes.css';
import { closeOutline, refresh } from 'ionicons/icons';
import { CalculationType } from '../models/calculation/CalculationType';
import CalculationTypesList from '../components/calculationTypesList/CalculationTypesList';
import { DB } from '../db/DB';
import { getCalculationTypesFromDB, storeCalculationTypeInDB, syncCalculationTypes } from '../utils/dbUtils';
import { Api } from '../api/Api';
import { getClientInformation, IClientInformation } from '../utils/clientUtils';
import { ActivationDto } from '../models/dtos/ActivationDto';

interface ICalculationTypesProps {
  loadCalculationTypes: () => void;
}

const CalculationTypes: React.FC<ICalculationTypesProps> = (props) => {

  const [debug] = useState<boolean>(false);
  const [debugCalculationTypes] = useState<Array<CalculationType>>([
    new CalculationType({
      id: 0, 
      description: 'Test 1', 
      domain: 'DEBUG',
      domainDescription: 'Debug'
    }),
    new CalculationType({
      id: 1, 
      description: 'Test 2', 
      domain: 'DEBUG',
      domainDescription: 'Debug'
    })
  ]);

  const [calculationTypes, setCalculationTypes] = useState<Array<CalculationType>>([
    new CalculationType({
      id: 0, 
      description: 'Standard', 
      domain: 'TEMP',
      domainDescription: ''
    })
  ]);
  const [groupedCalculationTypes, setGroupedCalculationTypes] = useState<any>({
    TEMP: [ 
      calculationTypes[0]
    ]
  });
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showAddCalculationTypeAlert, setShowAddCalculationTypeAlert] = useState<boolean>(false);
  const [showAddCalculationTypesFailedToast, setShowAddCalculationTypesFailedToast] = useState<boolean>(false);
  const [showAddCalculationTypesSuccededToast, setShowAddCalculationTypesSuccededToast] = useState<boolean>(false);

  const refresher = useRef<any>(null);

  useEffect(() => {
    loadCalculationTypes();
    // eslint-disable-next-line
  }, []);

  const loadCalculationTypes = async () => {
    const db = new DB();

    await syncCalculationTypes(db);

    let defaultCalculationType = new CalculationType({
      id: 0, 
      description: 'Standard', 
      domain: 'DEFAULT',
      domainDescription: ''
    });
    const calculationTypesFromDB: CalculationType[] = await getCalculationTypesFromDB(db);
    if (calculationTypesFromDB.length === 0) {
      await storeCalculationTypeInDB(db, defaultCalculationType);
      calculationTypesFromDB.push(defaultCalculationType);
    }

    if (debug) {
      debugCalculationTypes.forEach(debugCalculationType => {
        calculationTypesFromDB.push(debugCalculationType);
      })
    }

    setCalculationTypes(calculationTypesFromDB);
    const index = calculationTypesFromDB.findIndex(c => c.domain === 'DEFAULT');
    if (index >= 0) {
      defaultCalculationType = calculationTypesFromDB[index];
    }

    setGroupedCalculationTypes({
      ...calculationTypesFromDB.reduce((r, a) => {
        r[a.domain] = [...r[a.domain] || [], a];
        return r;
      }, {})
    });
  }

  const doRefresh = async (fromButton: boolean = false) => {
    if (fromButton) {
      setShowLoading(true);
    }
    setTimeout(async () => {
      await loadCalculationTypes();
      refresher.current.complete();
      if (fromButton) {
        setShowLoading(false);
      }
    }, 500);
  }

  const doAuthorizeActivation = async (domain: string, code: string) => {
    const api = new Api();
    const clientInformation: IClientInformation|undefined = await getClientInformation();
    if (typeof clientInformation !== 'undefined') {
      api.token = clientInformation.accessToken;
    }
    const activationDto = new ActivationDto();
    activationDto.serverCode = domain;
    activationDto.authorizationCode = code;
    const response = await api.authorizeActivation(activationDto);
    if (typeof response !== 'undefined') {
      if (response.status === 202) {
        await doRefresh(true);
        setShowAddCalculationTypesSuccededToast(true);
      } else {
        setShowAddCalculationTypesFailedToast(true);
      }
    } else {
      setShowAddCalculationTypesFailedToast(true);
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Kalkulationstypen</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => doRefresh(true)} class={isPlatform('desktop') ? '' : 'ion-hide'}>
              <IonIcon slot="icon-only" icon={refresh}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} class={isPlatform('desktop') ? 'content-desktop' : ''}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle color="dark" size="large">Kalkulationstypen</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonRefresher ref={refresher} slot="fixed" onIonRefresh={() => doRefresh()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {Object.keys(groupedCalculationTypes).map(domain => {
          if (domain === 'DEFAULT') {
            return (
              <CalculationTypesList key={domain} calculationTypes={groupedCalculationTypes[domain]} 
                title={groupedCalculationTypes[domain][0].domainDescription} 
                hideTitle={true}/>
            );
          } else {
            return null;
          }
        })}

        {Object.keys(groupedCalculationTypes).map(domain => {
          if (domain !== 'DEFAULT') {
            return (
              <CalculationTypesList key={domain} calculationTypes={groupedCalculationTypes[domain]} 
                title={groupedCalculationTypes[domain][0].domainDescription} 
                hideTitle={false}/>
            );
          } else {
            return null;
          }
        })}

        <IonGrid class="ion-no-padding ion-padding-vertical">
          <IonRow class="ion-align-items-start ion-justify-content-start ion-padding-top">
            <IonCol class="ion-no-padding" size="12" sizeMd="12">
              <IonItem lines="none">
                <IonLabel color="medium" class="ion-text-wrap">Um neue Kalkulationstypen hinzufügen, erstellen Sie in LeasOne einen Autorisierungscode und geben diesen hier ein.</IonLabel>
              </IonItem>
            </IonCol>
          </IonRow>
          <IonRow class="ion-align-items-start ion-justify-content-start ion-margin-bottom">
            <IonCol class="ion-no-padding" size="12" sizeMd="5">
							<IonButton
								key="button-add-calculation-types"
                expand="block"
                class="button-aktionen ion-margin-start"
								color="light"
                onClick={() => setShowAddCalculationTypeAlert(true)}>
                Kalkulationstypen hinzufügen
              </IonButton>
						</IonCol>
          </IonRow>
        </IonGrid>

        <IonAlert
          isOpen={showAddCalculationTypeAlert}
          onDidDismiss={() => setShowAddCalculationTypeAlert(false)}
          cssClass="alert-add-calculation-types "
          header="Kalkulationtypen hinzufügen"
          message="Geben Sie Ihre LeasOne-Domain und den Autorisierungscode ein, um neue Kalkulationstypen hinzuzufügen."
          inputs={[
            {
              name: 'newCalculationTypeDomain',
              type: 'text',
              id: 'newCalculationTypeDomain',
              label: 'Domain',
              value: '',
              placeholder: 'Domain eingeben',
              attributes: {
                autoCapitalize: 'characters'
              }
            },
            {
              name: 'newCalculationTypeCode',
              type: 'text',
              id: 'newCalculationTypeCode',
              label: 'Autorisierungscode',
              value: '',
              placeholder: 'Autorisierungscode eingeben'
            }
          ]}
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel'
            },
            {
              text: 'Hinzufügen',
              handler: (data) => {
                doAuthorizeActivation(data.newCalculationTypeDomain, data.newCalculationTypeCode);
              }
            }
          ]} />

        <IonToast
          isOpen={showAddCalculationTypesFailedToast}
          onDidDismiss={() => setShowAddCalculationTypesFailedToast(false)}
          message="Hinzufügen fehlgeschlagen"
          cssClass="toast-add-calculation-types-failed"
          position="top"
          color="danger"
          duration={3500}
          buttons={[
            {
              side: 'end',
              icon: closeOutline,
              role: 'cancel'
            }
          ]}
        />

        <IonToast
          isOpen={showAddCalculationTypesSuccededToast}
          onDidDismiss={() => setShowAddCalculationTypesSuccededToast(false)}
          message="Kalkulationstypen hinzugefügt"
          cssClass="toast-add-calculation-types-succeded"
          position="top"
          color="success"
          duration={3500}
          buttons={[
            {
              side: 'end',
              icon: closeOutline,
              role: 'cancel'
            }
          ]}
        /> 

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Aktualisieren...'}
          duration={1000}
        />

      </IonContent>
    </IonPage>
  );
};

export default CalculationTypes;
