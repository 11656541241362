import { CalculationStatus } from '../../enums/CalculationStatus';
import { DissolveBy } from '../../enums/DissolveBy';
import { PaymentMethod } from '../../enums/PaymentMethod';
import { PaymentStep } from '../../enums/PaymentStep';
import { Reference } from '../../enums/Reference';
import { calculateTermIterative, calculateFinalPayment, calculateInstallment, calculateInterestIterative, calculatePresentValue, getPeriodicInterest, roundCommercially } from '../../utils/calculationUtils';
import { convertNumberToGermanNumberFormat } from '../../utils/calculatorUtils';
import { ICalculationOptions } from '../options/ICalculationOptions';
import { ICalculation } from './ICalculation';
import { ICalculationField } from './ICalculationField';

export class Calculation implements ICalculation {
	id: number = 0;
	description: string = 'Kalkulation';
	crtDate: Date = new Date();
	chgDate: Date|null = null;
	pinned: boolean = false;
	deleted: boolean = false;
	status: CalculationStatus = CalculationStatus.New;
	
	presentValue: ICalculationField = {
		value: 0, 
		reference: Reference.None
	};
	presentValueOrigin: ICalculationField = {
		value: 0, 
		reference: Reference.None
	};
	finalPayment: ICalculationField = {
		value: 0, 
		reference: Reference.None
	};
	listPrice: ICalculationField = {
		value: 0, 
		reference: Reference.NewPurchaseCostsPlusDiscount
	};
	discount: ICalculationField = {
		value: 0, 
		reference: Reference.ListPriceMinusNewPurchaseCosts
	};
	newPurchaseCosts: ICalculationField = {
		value: 0, 
		reference: Reference.ListPriceMinusDiscount
	};
	specialPayment: ICalculationField = {
		value: 0, 
		reference: Reference.NewPurchaseCosts
	};
	presentValueMargin: ICalculationField = {
		value: 0, 
		reference: Reference.NewPurchaseCostsMinusSpecialPayment
	};
	term: ICalculationField = {
		value: 0, 
		reference: Reference.None
	};
	interest: ICalculationField = {
		value: 0, 
		reference: Reference.None
	};
	residualValue: ICalculationField = {
		value: 0, 
		reference: Reference.ListPrice
	};
	installment: ICalculationField = {
		value: 0, 
		reference: Reference.RentAssessmentBase
	};
	
	paymentStep: PaymentStep = PaymentStep.Monthly;
	paymentMethod: PaymentMethod = PaymentMethod.InAdvance;
	annualInterestNominal: number = 0;
	annualInterestEffective: number = 0;

	dissolveBy: DissolveBy = DissolveBy.Installment;
	rentAssessmentBase: Reference = Reference.NewPurchaseCostsMinusSpecialPayment;

	calculationTypeId: number = 1;

	constructor(options: ICalculationOptions = {}) {
		this.description = 'Kalkulation vom ' + this.getFormattedDateString();
		for (let key in options) {
			this[key] = options[key];
		}
	}

	/**
	 * Dissolves by value which has been set in dissolveBy property 
	 */
	calculate = () => {
		switch (this.dissolveBy) {
			case DissolveBy.NewPurchaseCosts:
				this.calculateNewPurchaseCosts();
				break;
			case DissolveBy.SpecialPayment:
				this.calculateSpecialPayment();
				break;
			case DissolveBy.PresentValueMargin:
				this.calculatePresentValueMargin();
				break;
			case DissolveBy.Term:
				this.calculateTerm();
				break;
			case DissolveBy.Interest:
				this.calculateInterest();
				break;
			case DissolveBy.ResidualValue:
				this.calculateResidualValue();
				break;
			case DissolveBy.Installment:
				this.calculateInstallment();
				break;
			default:
				break;
		}
	}

	private calculatePresentValue = () => {
		this.finalPayment.value = this.residualValue.value;
		this.presentValue.value = roundCommercially(calculatePresentValue(this.finalPayment.value, this.installment.value, getPeriodicInterest(this.interest.value, this.paymentStep), this.paymentMethod, roundCommercially(this.term.value, 0) / this.paymentStep));
	}

	private calculateNewPurchaseCosts = () => {
		this.calculatePresentValue();
		this.presentValueOrigin.value = this.presentValue.value;
		this.newPurchaseCosts.value = this.presentValue.value;
	}

	private calculateSpecialPayment = () => {
		this.calculatePresentValue();
		this.presentValueOrigin.value = this.presentValue.value;
		this.specialPayment.value = this.newPurchaseCosts.value - this.presentValue.value + this.presentValueMargin.value;
	}

	private calculatePresentValueMargin = () => {
		this.calculatePresentValue();
		this.presentValueOrigin.value = this.presentValue.value;
		this.presentValueMargin.value = this.presentValue.value - this.newPurchaseCosts.value + this.specialPayment.value;
	}

	private calculateTerm = () => {
		this.presentValueOrigin.value = this.newPurchaseCosts.value - this.specialPayment.value + this.presentValueMargin.value;
		const startPeriods: number = 12;
		const steps: number = 10;
		const periodicInterest = getPeriodicInterest(this.interest.value, this.paymentStep);

		if (periodicInterest === 0) {
			this.term.value = roundCommercially(((this.presentValueOrigin.value - this.residualValue.value) / this.installment.value) * this.paymentStep, 0);
		} else {
			this.term.value = roundCommercially(calculateTermIterative(this.presentValueOrigin.value, this.residualValue.value, this.installment.value, getPeriodicInterest(this.interest.value, this.paymentStep), this.paymentMethod, startPeriods, steps), 0);
		}

		this.calculatePresentValue();
	}

	private calculateInterest = () => {
		this.presentValueOrigin.value = this.newPurchaseCosts.value - this.specialPayment.value + this.presentValueMargin.value;
		const startInterest: number = (5 / 100) / 12;
		const steps: number = 10;

		this.interest.value = roundCommercially(calculateInterestIterative(this.presentValueOrigin.value, this.residualValue.value, this.installment.value, this.paymentMethod, roundCommercially(this.term.value, 0) / this.paymentStep, steps, startInterest) * 100 * (12 / this.paymentStep));

		if (this.interest.value < 0) {
			console.log('interest < 0', this.interest.value);
			this.interest.value = 0;
		}
		if (this.interest.value > 100) {
			console.log('interest > 100', this.interest.value);
			this.interest.value = 100;
		}

		this.calculatePresentValue();
	}

	private calculateResidualValue = () => {
		this.presentValueOrigin.value = this.newPurchaseCosts.value - this.specialPayment.value + this.presentValueMargin.value;
		const periodicInterest = getPeriodicInterest(this.interest.value, this.paymentStep);

		if (periodicInterest === 0) {
			// TODO
		} else {
			this.finalPayment.value = roundCommercially(calculateFinalPayment(this.presentValueOrigin.value, this.installment.value, periodicInterest, this.paymentMethod, roundCommercially(this.term.value, 0) / this.paymentStep));
		}

		this.residualValue.value = this.finalPayment.value;
		this.calculatePresentValue();
	}

	private calculateInstallment = () => {
		this.presentValueOrigin.value = this.newPurchaseCosts.value - this.specialPayment.value + this.presentValueMargin.value;

		this.installment.value = roundCommercially(calculateInstallment(this.presentValueOrigin.value, this.residualValue.value, getPeriodicInterest(this.interest.value, this.paymentStep), this.paymentMethod, roundCommercially(this.term.value, 0) / this.paymentStep));

		this.calculatePresentValue();
	}

	/**
	 * Generates summary of current calculation properties.
	 * 
	 * @returns {number} Calculation summary
	 */
	public getSummary = (): string => {
		return (
			'Barwert: ' + convertNumberToGermanNumberFormat(this.presentValue.value) + ' €, ' +
			'Listenpreis: ' + convertNumberToGermanNumberFormat(this.listPrice.value) + ' €,\n' + 
			'Rabatt: ' + convertNumberToGermanNumberFormat(this.discount.value) + ' €, ' + 
			'NAK: ' + convertNumberToGermanNumberFormat(this.newPurchaseCosts.value) + ' €,\n' + 
			'Sonderzahlung: ' + convertNumberToGermanNumberFormat(this.specialPayment.value) + ' €, ' + 
			'Marge: ' + convertNumberToGermanNumberFormat(this.presentValueMargin.value) + ' €,\n' + 
			'Laufzeit: ' + convertNumberToGermanNumberFormat(this.term.value) + ' Monate, ' + 
			'Zins: ' + convertNumberToGermanNumberFormat(this.interest.value) + ' €,\n' + 
			'Restwert: ' + convertNumberToGermanNumberFormat(this.residualValue.value) + ' €, ' + 
			'Rate: ' + convertNumberToGermanNumberFormat(this.installment.value) + ' €'
		);
	}

	private getFormattedDateString = (): string => {
		return new Intl.DateTimeFormat('de-DE', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		}).format(new Date());
	};

}
