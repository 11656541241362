import { CalculatorInput } from './CalculatorInput';
import { ISelectOptions } from '../options/ISelectOptions';
import { ISelectSelectOptions } from '../options/ISelectSelectOptions';

export class Select extends CalculatorInput {
  value: string|number;
  valueChanged: boolean;
	selectOptions: ISelectSelectOptions[];

  constructor(options: ISelectOptions) {
    super(options);
    this.value = options.value;
    this.valueChanged = options.valueChanged;
    this.selectOptions = options.selectOptions;
  }
}
