import React, { useEffect, useRef, useState } from 'react';
import './CalculationsList.css';
import { trashOutline } from 'ionicons/icons';
import { Calculation } from '../../models/calculation/Calculation';
import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, isPlatform } from '@ionic/react';
import { convertNumberToGermanNumberFormat } from '../../utils/calculatorUtils';

interface ICalculationsListProps {
	calculations: Calculation[];
	title: string;
	icon: string;
	handlePinClicked: (id: number) => void;
	handleDeleteClicked: (id: number) => void;
}

const CalculationsList: React.FC<ICalculationsListProps> = (props) => {

	const [calculations, setCalculations] = useState<Array<Calculation>>(props.calculations);
	const [hideList, setHideList] = useState<boolean>(props.calculations.length === 0);

	const list = useRef<any>(null);

	useEffect(() => {
		setCalculations(props.calculations.sort((a, b) => b.id - a.id));
		setHideList(props.calculations.length === 0)
	}, [props.calculations]);

	const handlePinClicked = (id: number) => {
		props.handlePinClicked(id);
		list.current.closeSlidingItems();
	}

	const handleDeleteClicked = (id: number) => {
		props.handleDeleteClicked(id);
		list.current.closeSlidingItems();
	}

	const getCalculationMeta = (calculation: Calculation): string => {
		return (
			'NAK: ' + convertNumberToGermanNumberFormat(calculation.newPurchaseCosts.value) + ' €, Rate: ' + convertNumberToGermanNumberFormat(calculation.installment.value) + ' €, Restwert: ' + convertNumberToGermanNumberFormat(calculation.residualValue.value) + ' €'
		);
	}

	const getFormattedCalculationDate = (calculation: Calculation): string => {
		let baseDate: Date = calculation.chgDate !== null ? calculation.chgDate : calculation.crtDate;
		const formattedDate: string = new Intl.DateTimeFormat('de-DE', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		}).format(baseDate);
		const formattedTime: string = new Intl.DateTimeFormat('de-DE', {
			hour: '2-digit',
			minute: '2-digit'
		}).format(baseDate);
		return 'Zuletzt geändert: ' + formattedDate + ', ' + formattedTime + ' Uhr';
	}

	return (
		<IonList ref={list} hidden={hideList} class={isPlatform('desktop') ? 'ion-padding-top list-desktop' : 'ion-padding-top'}>
			<IonListHeader>
				{props.title}
			</IonListHeader>
			{calculations.map(calculation => {
				return (
					<IonItemSliding key={calculation.id}>
						<IonItemOptions side="start">
							<IonItemOption color="warning" onClick={() => handlePinClicked(calculation.id)}>
								<IonIcon slot="icon-only" color="light" src={'assets/icons/' + props.icon + '.svg'} />
							</IonItemOption>
						</IonItemOptions>
						<IonItem detail={true} routerLink={'/calculations/' + calculation.id}>
							<IonLabel>
								<h2>{calculation.description}</h2>
								<h3>{getCalculationMeta(calculation)}</h3>
								<p>{getFormattedCalculationDate(calculation)}</p>
							</IonLabel>
							<div tabIndex={calculation.id}></div>
						</IonItem>
						<IonItemOptions side="end">
							<IonItemOption color="danger" onClick={() => handleDeleteClicked(calculation.id)}>
								<IonIcon slot="icon-only" icon={trashOutline} />
							</IonItemOption>
						</IonItemOptions>
					</IonItemSliding>
				);
			})}
		</IonList>
	);
};

export default CalculationsList;
