import Dexie from 'dexie';
import { DBCalculation } from '../models/db/DBCalculation';
import { DBCalculationType } from '../models/db/DBCalculationType';

export class DB extends Dexie {
  calculations: Dexie.Table<DBCalculation, number>;
  calculationTypes: Dexie.Table<DBCalculationType, number>;

	constructor() {  
    super('LeasOneFinCalc');

    this.version(1).stores({
      calculations: '++id, description, crtDate, chgDate, pinned, deleted',
      calculationTypes: '++id, description, crtDate, chgDate'
    });
    this.version(2).stores({
      calculationTypes: '++id, description, domain, crtDate, chgDate'
    });
    this.version(3).stores({
      calculations: '++id, description, crtDate, chgDate, pinned, deleted, status'
    });
    this.version(4).stores({
      calculationTypes: '++id, description, domain, crtDate, chgDate, [domain+originNr]'
    });

    this.calculations = this.table('calculations');
    this.calculationTypes = this.table('calculationTypes');
    this.calculations.mapToClass(DBCalculation);
    this.calculationTypes.mapToClass(DBCalculationType);
  }
}
