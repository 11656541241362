import { Api } from '../api/Api';
import { DB } from '../db/DB';
import { Calculation } from '../models/calculation/Calculation';
import { CalculationType } from '../models/calculation/CalculationType';
import { DBCalculation } from '../models/db/DBCalculation';
import { DBCalculationType } from '../models/db/DBCalculationType';
import { CalculationTypeDto } from '../models/dtos/CalculationTypeDto';
import { getClientInformation, IClientInformation } from './clientUtils';
import { mapDBCalculationTypeToCalculationType, mapCalculationTypeToDBCalculationType, mapDBCalculationToCalculation, mapCalculationToDBCalculation, mapCalculationTypeDtoToDBCalculationType } from './mapperUtils';

/**
 * Calculations
 */

export const getCalculationsFromDB = async (db: DB): Promise<Array<Calculation>> => {
	const calculations: Calculation[] = [];
	const calculationsFromDB = await db.calculations.toArray();
	calculationsFromDB.forEach(calculationFromDB => {
		calculations.push(mapDBCalculationToCalculation(calculationFromDB));
	});
	return calculations;
}

export const getPendingCalculationsFromDB = async (db: DB): Promise<Array<DBCalculation>> => {
	const calculations: DBCalculation[] = [];
	const calculationsFromDB = await db.calculations.where({status: 1}).toArray();
	calculationsFromDB.forEach(calculationFromDB => {
		calculations.push(calculationFromDB);
	});
	return calculations;
}

export const getCalculationByIdFromDB = async (db: DB, id: number): Promise<DBCalculation|undefined> => {
	const calculationFromDB = await db.calculations.get(id);
	return calculationFromDB;
}

export const storeCalculationInDB = async (db: DB, calculation: Calculation): Promise<number|undefined> => {
	const dbCalculation = mapCalculationToDBCalculation(calculation);
	try {
		if (calculation.id === 0) {
			delete dbCalculation.id;
		} 
		const id =  await db.calculations.put(dbCalculation);
		return id;
	} catch (error) {
		console.error(error.stack);
	}
}

export const updateCalculationInDB = async (db: DB, calculation: Calculation): Promise<number|undefined> => {
	const dbCalculation = mapCalculationToDBCalculation(calculation);
	try {
		if (calculation.id > 0) {
			const id =  await db.calculations.put(dbCalculation);
			return id;
		}
	} catch (error) {
		console.error(error.stack);
	}
}

/**
 * Calculation types
 */

export const syncCalculationTypes = async (db: DB) => {
	const api = new Api();
	const clientInformation: IClientInformation|undefined = await getClientInformation();
	if (typeof clientInformation !== 'undefined') {
		api.token = clientInformation.accessToken;
	}

	const response = await api.getCalculationTypes();
	
	if (typeof response !== 'undefined' && response.data) {
		const calculationTypeDtos: Array<CalculationTypeDto> = [];
		response.data.forEach((raw: any) => {
			const calculationTypeDto = new CalculationTypeDto();
			for (let key in raw) {
				if (calculationTypeDto.hasOwnProperty(key)) {
					calculationTypeDto[key] = raw[key];
				}
			}
			calculationTypeDtos.push(calculationTypeDto);
		});
		
		const storedCalculationTypeIds: Array<number> = await storeCalculationTypesInDB(db, calculationTypeDtos);
		const allCalculationTypes = await db.calculationTypes.toArray();
		for (let i = 0; i < allCalculationTypes.length; i++) {
			const calculationType = allCalculationTypes[i];
			if (typeof calculationType.id !== 'undefined' && !storedCalculationTypeIds.includes(calculationType.id) && calculationType.domain !== 'DEFAULT') {
				calculationType.chgDate = new Date();
				calculationType.deleted = true;
				await db.calculationTypes.put(calculationType);
			}
		}
	}
}

const storeCalculationTypesInDB = async (db: DB, calculationTypeDtos: Array<CalculationTypeDto>) => {
	const storedCalculationTypeIds: Array<number> = [];
	const asyncForEach = async (array: Array<any>, callback: any) => {
		for (let index = 0; index < array.length; index++) {
			await callback(array[index], index, array);
		}
	}
	await asyncForEach(calculationTypeDtos, async (calculationTypeDto: CalculationTypeDto) => {
    const calculationTypeFromDB = await db.calculationTypes.get({
			domain: calculationTypeDto.domain,
			originNr: calculationTypeDto.nr
		});
		const dbCalculationType = mapCalculationTypeDtoToDBCalculationType(calculationTypeDto);
		if (typeof calculationTypeFromDB !== 'undefined') {
			dbCalculationType.id = calculationTypeFromDB.id;
			dbCalculationType.chgDate = new Date();
			dbCalculationType.deleted = false;
		}
		dbCalculationType.crtDate = new Date();
		try {
			const id = await db.calculationTypes.put(dbCalculationType);
			if (typeof id !== 'undefined') {
				storedCalculationTypeIds.push(id);
			};
		} catch (error) {
			console.error(error.stack);
		}
  });
	return storedCalculationTypeIds;
}

export const getCalculationTypesFromDB = async (db: DB): Promise<Array<CalculationType>> => {
	const calculationTypes: CalculationType[] = [];
	const calculationTypesFromDB = await db.calculationTypes.toArray();
	calculationTypesFromDB.forEach(calculationTypeFromDB => {
		if (calculationTypeFromDB.deleted === false) {
			calculationTypes.push(mapDBCalculationTypeToCalculationType(calculationTypeFromDB));
		}
	});
	return calculationTypes;
}

export const getCalculationTypeByIdFromDB = async (db: DB, id: number): Promise<DBCalculationType|undefined> => {
	if (id === 0 ){
		const calculationTypeFromDB = await getDefaultCalculationTypeFromDB(db);
		return calculationTypeFromDB;
	} else {
		const calculationTypeFromDB = await db.calculationTypes.get(id);
		return calculationTypeFromDB;
	}
}

export const getDefaultCalculationTypeFromDB = async (db: DB): Promise<DBCalculationType|undefined> => {
	const calculationTypeFromDB = await db.calculationTypes.get({
		domain: 'DEFAULT'
	});
	return calculationTypeFromDB;
}

export const storeCalculationTypeInDB = async (db: DB, calculationType: CalculationType): Promise<number|undefined>  => {
	const dbCalculationType = mapCalculationTypeToDBCalculationType(calculationType);
	try {
		if (calculationType.id === 0) {
			delete dbCalculationType.id;
		} 
		const id =  await db.calculationTypes.put(dbCalculationType);
		return id;
	} catch (error) {
		console.error(error.stack);
	}
}

export const updateCalculationTypeInDB = async (db: DB, calculationType: CalculationType): Promise<number|undefined> => {
	const dbCalculationType = mapCalculationTypeToDBCalculationType(calculationType);
	try {
		if (calculationType.id > 0) {
			const id =  await db.calculationTypes.put(dbCalculationType);
			return id;
		}
	} catch (error) {
		console.error(error.stack);
	}
}
