import { CalculationStatus } from '../../enums/CalculationStatus';
import { DissolveBy } from '../../enums/DissolveBy';
import { PaymentMethod } from '../../enums/PaymentMethod';
import { PaymentStep } from '../../enums/PaymentStep';
import { Reference } from '../../enums/Reference';
import { IDBCalculationOptions } from '../options/IDBCalculationOptions';

export class DBCalculation {
	id?: number;
	description: string = '';
	crtDate: Date = new Date();
	chgDate: Date|null = null;
	pinned: boolean = false;
	deleted: boolean = false;
	status: CalculationStatus = CalculationStatus.New;
	presentValue: number = 0;
	presentValueOrigin: number = 0;
	listPrice: number = 0;
	discount: number = 0;
	newPurchaseCosts: number = 0;
	specialPayment: number = 0;
	presentValueMargin: number = 0;
	term: number = 0;
	interest: number = 0;
	residualValue: number = 0;
	installment: number = 0;
	paymentStep: PaymentStep = PaymentStep.Monthly
	paymentMethod: PaymentMethod = PaymentMethod.InAdvance;
	annualInterestRateNominal: number = 0;
	annualInterestRateEffective: number = 0;
	dissolveBy: DissolveBy = DissolveBy.Installment;
	rentAssessmentBase: Reference = Reference.NewPurchaseCosts;
	calculationTypeId: number = 1;

	constructor(options: IDBCalculationOptions = {}) {
		for (let key in options) {
			this[key] = options[key];
		}
	}

}
