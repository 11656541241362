// eslint-disable-next-line
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonListHeader, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import { Currencies, Money } from 'ts-money';
import './Calculator.css';
import { calculatePercentageFromValue, calculateValueFromPercentage, changeSelectedCalculationType, convertNumberToGermanNumberFormat, convertRawValueToDecimalNumber, generateCalculatorInputs, updateValuesAndPercentages } from '../utils/calculatorUtils';
import MoneyOrPercentageInput from '../components/moneyOrPercentageInput/MoneyOrPercentageInput';
import { ICalculatorInputs } from '../models/inputs/ICalculatorInputs';
import { MoneyOrPercentage } from '../models/inputs/MoneyOrPercentage';
import { Term } from '../models/inputs/Term';
import TermInput from '../components/termInput/TermInput';
import { ICalculatorInput } from '../models/inputs/ICalculatorInput';
import SelectInput from '../components/selectInput/SelectInput';
import { Select } from '../models/inputs/Select';
import { Calculation } from '../models/calculation/Calculation';
import { DissolveBy } from '../enums/DissolveBy';
import { calculator, closeOutline } from 'ionicons/icons';
import { roundCommercially } from '../utils/calculationUtils';
import { Field } from '../enums/Field';
import { Reference } from '../enums/Reference';
import { CalculationType } from '../models/calculation/CalculationType';
import { DB } from '../db/DB';
import { getCalculationTypesFromDB, storeCalculationInDB, updateCalculationInDB } from '../utils/dbUtils';
import { CalculationStatus } from '../enums/CalculationStatus';
import { Api } from '../api/Api';
import { getClientInformation, IClientInformation } from '../utils/clientUtils';
import { mapCalculationDtoToCalculation, mapCalculationToCalculationDto } from '../utils/mapperUtils';
import { CalculationDto } from '../models/dtos/CalculationDto';
import { useStatus } from '@capacitor-community/react-hooks/network';

interface ICalculatorProps {
  // calculationTypes: CalculationType[];
  selectedCalculationType: CalculationType;
  calculationToLoad: Calculation|undefined;
  // defaultCalculationTypeLoaded: boolean;
  // setSelectedCalculationType: (calculationType: CalculationType) => void;
  resetLoadCalculationInCalculator: () => void;
  doReloadCalculations: () => void;
  // setDefaultCalculationType: () => void;
}

const Calculator: React.FC<ICalculatorProps> = (props) => {

  const { networkStatus } = useStatus();

  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [resetButtonDisabled, setResetButtonDisabled] = useState<boolean>(true);

  const [selectedCalculationType, setSelectedCalculationType] = useState<CalculationType>(new CalculationType({
    id: 0, 
    description: 'Standard', 
    domain: 'TEMP',
    domainDescription: ''
  }));
  const [calculationTypes, setCalculationTypes] = useState<Array<CalculationType>>([selectedCalculationType]);
  const [defaultCalculationTypeLoaded, setDefaultCalculationTypeLoaded] = useState<boolean>(false);

  const [calculation, setCalculation] = useState<Calculation>(new Calculation());
  const [calculationTypeId, setCalculationTypeId] = useState<number>(selectedCalculationType.id);
  const [calculationId, setCalculationId] = useState<number>(calculation.id);
  const [rentAssessmentBase, setRentAssessmentBase] = useState<Reference>(calculation.rentAssessmentBase);
  const [presentValueOutput, setPresentValueOutput] = useState<string>('0,00');
  const [presentValueDifferenceOutput, setPresentValueDifferenceOutput] = useState<string>('0,00');
  const [showResetCalculatorAlert, setShowResetCalculatorAlert] = useState<boolean>(false);
  const [showSaveCalculationAlert, setShowSaveCalculationAlert] = useState<boolean>(false);
  const [showCalculateOfflineAlert, setShowCalculateOfflineAlert] = useState<boolean>(false);
  const [showCalculationSavedToast, setShowCalculationSavedToast] = useState<boolean>(false);
  const [calculationDescription, setCalculationDescription] = useState<string>(calculation.description);

  const paymentStepColElement = useRef<any>(null);
  const paymentMethodColElement = useRef<any>(null);

  const [calculatorInputs, setCalculatorInputs] = useState<ICalculatorInputs>(generateCalculatorInputs(selectedCalculationType));

  useEffect(() => {
    if (!initialLoad && typeof props.calculationToLoad === 'undefined') {
      loadCalculationTypes();
      setInitialLoad(true);
    }
    // eslint-disable-next-line
  }, [initialLoad, calculatorInputs]);

  useEffect(() => {
    if (defaultCalculationTypeLoaded && typeof props.calculationToLoad === 'undefined') {
      const newCalculatorInputs: ICalculatorInputs = generateCalculatorInputs(selectedCalculationType);

      newCalculatorInputs.installment.dissolveBy = true;
      if (isPlatform('desktop')) {
        setTimeout(() => {
          newCalculatorInputs.listPrice.isFocussed = true;
          setCalculatorInputs({
            ...newCalculatorInputs
          });
        }, 600);
      }

      setCalculatorInputs({
        ...newCalculatorInputs
      });
    }
    // eslint-disable-next-line
  }, [defaultCalculationTypeLoaded]);

  // handle calculation to load
  useEffect(() => {
    if (typeof props.calculationToLoad !== 'undefined') {
      
      setSelectedCalculationType(props.selectedCalculationType);
      setCalculationTypeId(props.selectedCalculationType.id);
      setCalculationId(props.calculationToLoad.id);
      setCalculationDescription(props.calculationToLoad.description);
      setResetButtonDisabled(false);
      setCalculation(props.calculationToLoad);

      const newCalculatorInputs: ICalculatorInputs = generateCalculatorInputs(props.selectedCalculationType);

      // set dissvolve by and values
      for (let key of Object.keys(newCalculatorInputs)) {
        let calculationField = props.calculationToLoad[key];
        let calculatorInput: ICalculatorInput = newCalculatorInputs[key];
        if (calculatorInput instanceof MoneyOrPercentage || calculatorInput instanceof Term) {
          // set dissolve by
          if (key === props.calculationToLoad.dissolveBy) {
            calculatorInput.dissolveBy = true;
          } else {
            calculatorInput.dissolveBy = false;
          }
          // set value
          if (calculationField.value > 0) {
            calculatorInput.value = calculationField.value;
          }
        }
      }

      // set raws
      for (let key of Object.keys(newCalculatorInputs)) {
        let calculatorInput: ICalculatorInput = newCalculatorInputs[key];
        if (calculatorInput instanceof MoneyOrPercentage) {
          if (calculatorInput.value > 0) {
            calculatorInput.percent = calculatePercentageFromValue(newCalculatorInputs, calculatorInput.id, props.calculationToLoad.rentAssessmentBase);
            if (calculatorInput.inEuros) {
              calculatorInput.raw = convertNumberToGermanNumberFormat(calculatorInput.value);
            } else {
              calculatorInput.raw = convertNumberToGermanNumberFormat(calculatorInput.percent, 3);
            }
            calculatorInput.rawChanged = true;
          }
        } else if (calculatorInput instanceof Term) {
          calculatorInput.raw = calculatorInput.value;
          calculatorInput.rawChanged = true;
        }
      }

      // set other fields
      newCalculatorInputs.paymentStep.value = props.calculationToLoad.paymentStep;
      newCalculatorInputs.paymentStep.valueChanged = true;
      newCalculatorInputs.paymentMethod.value = props.calculationToLoad.paymentMethod;
      newCalculatorInputs.paymentMethod.valueChanged = true;
      setPresentValueOutput(convertNumberToGermanNumberFormat(props.calculationToLoad.presentValue.value));
      setPresentValueDifferenceOutput(getPresentValueDifferenceString(props.calculationToLoad));
      
      setCalculatorInputs({...newCalculatorInputs});
      props.resetLoadCalculationInCalculator();
    }
  }, [props]);

  useEffect(() => {
    setRentAssessmentBase(selectedCalculationType.rentAssessmentBase);
  }, [selectedCalculationType.rentAssessmentBase]);
  
  const loadCalculationTypes = async () => {
    const db = new DB();
    let defaultCalculationType = new CalculationType({
      id: 0, 
      description: 'Standard', 
      domain: 'DEFAULT',
      domainDescription: ''
    });
    const calculationTypesFromDB: CalculationType[] = await getCalculationTypesFromDB(db);
    if (calculationTypesFromDB.length === 0) {
      calculationTypesFromDB.push(defaultCalculationType);
    }

    setCalculationTypes(calculationTypesFromDB);
    const index = calculationTypesFromDB.findIndex(c => c.domain === 'DEFAULT');
    if (index >= 0) {
      defaultCalculationType = calculationTypesFromDB[index];
    }
    setSelectedCalculationType(defaultCalculationType);
    setCalculationTypeId(defaultCalculationType.id);
    setDefaultCalculationTypeLoaded(true);
  }

  const reloadCalculationTypes = async (calculationType: CalculationType) => {
    const db = new DB();
    let defaultCalculationType = new CalculationType({
      id: 0, 
      description: 'Standard', 
      domain: 'DEFAULT',
      domainDescription: ''
    });
    const calculationTypesFromDB: CalculationType[] = await getCalculationTypesFromDB(db);
    if (calculationTypesFromDB.length === 0) {
      calculationTypesFromDB.push(defaultCalculationType);
    }

    setCalculationTypes(calculationTypesFromDB);
    const indexDefault = calculationTypesFromDB.findIndex(c => c.domain === 'DEFAULT');
    if (indexDefault >= 0) {
      defaultCalculationType = calculationTypesFromDB[indexDefault];
    }
    const index = calculationTypesFromDB.findIndex(c => c.id === calculationType.id);
    if (index >= 0) {
      setSelectedCalculationType(calculationTypesFromDB[index]);
      setCalculationTypeId(calculationTypesFromDB[index].id);
      return calculationTypesFromDB[index];
    } else {
      setSelectedCalculationType(defaultCalculationType);
      setCalculationTypeId(defaultCalculationType.id);
      return defaultCalculationType;
    }
  }

  const onCalculationTypeChange = (newCalculationTypeId: number) => {
    if (newCalculationTypeId !== calculationTypeId) {
      setCalculationTypeId(newCalculationTypeId);
      const newCalculationType: CalculationType|undefined = changeSelectedCalculationType(calculationTypes, newCalculationTypeId);
      if (newCalculationType !== undefined) {
        setSelectedCalculationType(newCalculationType);
        onResetCalculator(newCalculationType, false);
      }
    }
  }

  const onResetCalculator = async (calculationType: CalculationType, fromButton: boolean = true) => {
    const realoadedCalculationType = await reloadCalculationTypes(calculationType);
    const newCalculatorInputs: ICalculatorInputs = generateCalculatorInputs(realoadedCalculationType);

    const newCalculation = new Calculation();
    if (!calculationType.installment.hidden && !calculationType.installment.disabled) {
      newCalculatorInputs.installment.dissolveBy = true;
      newCalculation.dissolveBy = DissolveBy.Installment;
    } else if (!calculationType.specialPayment.hidden && !calculationType.specialPayment.disabled) {
      newCalculatorInputs.specialPayment.dissolveBy = true;
      newCalculation.dissolveBy = DissolveBy.SpecialPayment;
    } else if (!calculationType.residualValue.hidden && !calculationType.residualValue.disabled) {
      newCalculatorInputs.residualValue.dissolveBy = true;
      newCalculation.dissolveBy = DissolveBy.ResidualValue;
    }

    setCalculatorInputs({...newCalculatorInputs});
    setCalculation(newCalculation);
    setCalculationId(0);
    setCalculationDescription(new Calculation().description);
    setPresentValueOutput('0,00');
    setPresentValueDifferenceOutput('0,00');
    if (fromButton) {
      loadCalculationTypes();
    }
    setResetButtonDisabled(true);

    if (newCalculatorInputs.paymentStep.hidden) {
      paymentStepColElement.current.classList.add('ion-hide');
      paymentStepColElement.current.classList.remove('hydrated');
    } else {
      paymentStepColElement.current.classList.add('hydrated');
      paymentStepColElement.current.classList.remove('ion-hide');
    }
    if (newCalculatorInputs.paymentMethod.hidden) {
      paymentMethodColElement.current.classList.add('ion-hide');
      paymentMethodColElement.current.classList.remove('hydrated');
    } else {
      paymentMethodColElement.current.classList.add('hydrated');
      paymentMethodColElement.current.classList.remove('ion-hide');
    }
  }

  const onSaveCalculation = async (calculationDescription: string, newCalculation: boolean = true) => {
    const calculationSave = await calculate(true);
    const db = new DB();
    if (newCalculation) {
      calculationSave.id = 0;
      calculation.pinned = false;
    }
    calculationSave.description = calculationDescription;
    let id: number|undefined = undefined;
    if (newCalculation) {
      id = await storeCalculationInDB(db, calculationSave);
    } else {
      calculationSave.chgDate = new Date();
      id = await updateCalculationInDB(db, calculationSave);
    }
    if (typeof id !== 'undefined') {
      calculation.id = id;
      setCalculationDescription(calculation.description);
      setCalculation(calculationSave);
      setCalculationId(id);
      setShowCalculationSavedToast(true);
      props.doReloadCalculations();
    }
  }
  
  const onBlur = (id: Field, raw: string|number) => {
    const element: ICalculatorInput = calculatorInputs[id];
    if (element instanceof MoneyOrPercentage && typeof raw === 'string' && raw.length > 0) {
      const value: number = convertRawValueToDecimalNumber(raw, element.decimals);
      element.rawChanged = true;
      if (element.inEuros) {
				element.raw = convertNumberToGermanNumberFormat(value);
				element.value = value;
        element.money = Money.fromDecimal(value, Currencies.EUR, Math.ceil);
        element.percent = calculatePercentageFromValue(calculatorInputs, element.id, rentAssessmentBase);
      } else {
        if (value < 0) {
          element.percent = 0;
        } else if (value > 100) {
          element.percent = 100;
        } else {
          element.percent = value;
        }
        element.value = calculateValueFromPercentage(calculatorInputs, element.id, rentAssessmentBase);
        element.raw = convertNumberToGermanNumberFormat(element.percent, 3);
      }
      let tempCalculatorInputs: ICalculatorInputs = updateValuesAndPercentages(calculatorInputs, element.id, rentAssessmentBase);
      setCalculatorInputs({...tempCalculatorInputs});
      setResetButtonDisabled(false);
    } else if (element instanceof Term) {
      let value: number;
      if (typeof raw === 'string') {
        value = parseInt(raw);
      } else {
        value = raw;
      }
      element.rawChanged = true;
      element.raw = value;
      element.value = value;
      setCalculatorInputs(
        {...calculatorInputs}
      );
      setResetButtonDisabled(false);
    }
  }

  const onChange = (id: string) => (value: any) => {
    const element: ICalculatorInput = calculatorInputs[id];
    if (element instanceof Select) {
      element.value = value;
      setCalculatorInputs(
        {...calculatorInputs}
      );
      setResetButtonDisabled(false);
    }
  }

  const onDissolveByChange = (value: DissolveBy) => {
    for (let key of Object.keys(calculatorInputs)) {
      let calculatorInput: ICalculatorInput = calculatorInputs[key];
      if (key === value) {
        if (calculatorInput instanceof MoneyOrPercentage) {
          calculatorInput.dissolveBy = true;
          // calculatorInput.value = 0;
          // calculatorInput.percent = 0;
          // calculatorInput.raw = convertNumberToGermanNumberFormat(calculatorInput.value, calculatorInput.inEuros ? 2 : 3);
          // calculatorInput.rawChanged = true;
        } else if (calculatorInput instanceof Term) {
          calculatorInput.dissolveBy = true;
        }
      } else {
        if (calculatorInput instanceof MoneyOrPercentage || calculatorInput instanceof Term) {
          calculatorInput.dissolveBy = false;
        }
      }
    }
    calculation.dissolveBy = value;
    setCalculatorInputs(
      {...calculatorInputs}
    );
    setCalculation(calculation);
    setResetButtonDisabled(false);
  }

  const onEuroOrPercentButtonClicked = (id: string) => {
    const element: ICalculatorInput = calculatorInputs[id];
    if (element instanceof MoneyOrPercentage) {
      element.rawChanged = true;
      if (element.inEuros) {
        element.raw = convertNumberToGermanNumberFormat(element.percent, 3);
        element.inEuros = false;
      } else {
        element.raw = convertNumberToGermanNumberFormat(element.value);
        element.inEuros = true;
      }
    }
    setCalculatorInputs(
      {...calculatorInputs}
    );
    setResetButtonDisabled(false);
  }

  const focusPreviousOrNextCalculationInput = (id: string, e: KeyboardEvent) => {
    const index = Object.keys(calculatorInputs).indexOf(id);
    if (e.key === 'Enter' || (e.key === 'Tab' && !e.shiftKey)) {
      const currentIndex: number|undefined = Object.keys(calculatorInputs).indexOf(id);
      if (typeof currentIndex !== 'undefined') {
        calculatorInputs[Object.keys(calculatorInputs)[index]].isFocussed = false;
        let element: ICalculatorInput;
        let nextIndex: number = currentIndex;
        let nextElementFocussable: boolean = false;
        while (!nextElementFocussable) {
          if (nextIndex < Object.keys(calculatorInputs).length) {
            nextIndex++;
          } else {
            nextIndex = 0;
          }
          element = calculatorInputs[Object.keys(calculatorInputs)[nextIndex]];
          if ((element instanceof MoneyOrPercentage || element instanceof Term) && !element.disabled && !element.dissolveBy) {
            element.isFocussed = true;
            nextElementFocussable = true;
          }
          setCalculatorInputs({
            ...calculatorInputs
          });
        }
      }
    } else if (e.key === 'Tab' && e.shiftKey) {
      const currentIndex: number|undefined = Object.keys(calculatorInputs).indexOf(id);
      if (typeof currentIndex !== 'undefined') {
        calculatorInputs[Object.keys(calculatorInputs)[index]].isFocussed = false;
        let element: ICalculatorInput;
        let previousIndex: number = currentIndex;
        let previousElementFocussable: boolean = false;
        while (!previousElementFocussable) {
          if (previousIndex > 0) {
            previousIndex--;
          } else {
            previousIndex = Object.keys(calculatorInputs).length - 1;
          }
          element = calculatorInputs[Object.keys(calculatorInputs)[previousIndex]];
          if ((element instanceof MoneyOrPercentage || element instanceof Term) && !element.disabled && !element.dissolveBy) {
            element.isFocussed = true;
            previousElementFocussable = true;
          }
        }
        setCalculatorInputs({
          ...calculatorInputs
        });
      }
    }
  }

  const resetRawChanged = (id: string) => {
    const element: ICalculatorInput = calculatorInputs[id];
    if (element instanceof MoneyOrPercentage || element instanceof Term) {
      element.rawChanged = false;
      setCalculatorInputs(
        {...calculatorInputs}
      );
    }
  }

  const resetValueChanged = (id: string) => {
    const element: ICalculatorInput = calculatorInputs[id];
    if (element instanceof Select) {
      element.valueChanged = false;
      setCalculatorInputs(
        {...calculatorInputs}
      );
    }
  }

  const resetFocus = (id: string) => {
    const element: ICalculatorInput = calculatorInputs[id];
    element.isFocussed = false;
    setCalculatorInputs(
      {...calculatorInputs}
    );
  }

  const generateInput = (element: ICalculatorInput, euroOrPercentButtonDisabled: boolean|undefined, inputMode: string|undefined, enterKeyHint: string|undefined, tabIndex: number, colElement?: any) => {
    if (true) {
      if (element instanceof MoneyOrPercentage && typeof euroOrPercentButtonDisabled !=='undefined') {
        return (
          <IonCol class="" size="12" sizeMd="6" hidden={element.hidden}>
              <MoneyOrPercentageInput id={element.id} label={element.label} raw={element.raw} rawChanged={element.rawChanged} inEuros={element.inEuros} isFocussed={element.isFocussed} euroOrPercentButtonDisabled={euroOrPercentButtonDisabled} placeholder={(!element.inEuros) ? '0,000' : '0,00'} inputMode={inputMode} enterKeyHint={enterKeyHint} tabIndex={tabIndex} disabled={element.disabled} dissvolveBy={element.dissolveBy} onBlur={onBlur} onEuroOrPercentButtonClicked={onEuroOrPercentButtonClicked} focusPreviousOrNextCalculationInput={focusPreviousOrNextCalculationInput} resetRawChanged={resetRawChanged} resetFocus={resetFocus}></MoneyOrPercentageInput>
            </IonCol>
        );
      } else if (element instanceof Term) {
        return (
          <IonCol class="" size="12" sizeMd="6" hidden={element.hidden}>
            <TermInput id={element.id} label={element.label} min={element.min} max={element.max} default={element.value} raw={element.raw} rawChanged={element.rawChanged} isFocussed={element.isFocussed} placeholder={element.value.toString()} inputMode={inputMode} enterKeyHint={enterKeyHint} tabIndex={tabIndex} disabled={element.disabled} dissvolveBy={element.dissolveBy} onBlur={onBlur} focusPreviousOrNextCalculationInput={focusPreviousOrNextCalculationInput} resetRawChanged={resetRawChanged} resetFocus={resetFocus}></TermInput>
          </IonCol>
        );
      } else if (element instanceof Select && typeof element.selectOptions !=='undefined') {
        return (
          <IonCol ref={colElement} class={element.hidden ? 'ion-hide' : ''} size="12" sizeMd="6">
            <SelectInput id={element.id} label={element.label} value={element.value} valueChanged={element.valueChanged} selectOptions={element.selectOptions} isFocussed={element.isFocussed} tabIndex={tabIndex} disabled={element.disabled} onChange={onChange} focusPreviousOrNextCalculationInput={focusPreviousOrNextCalculationInput} resetValueChanged={resetValueChanged} resetFocus={resetFocus}></SelectInput>
          </IonCol>
        );
      }
    }
  }

  const calculate = async (fromSaveButton: boolean = false): Promise<Calculation> => {
    for (const id in calculatorInputs) {
      const element: ICalculatorInput = calculatorInputs[id];
      if (element instanceof MoneyOrPercentage) {
        calculation[id].value = element.value;
        calculation[id].reference = element.refercence;
      } else if (element instanceof Term) {
        calculation[id].value = element.value;
      } else if (element instanceof Select) {
        calculation[id] = element.value;
      }
    }

    // standard
    if (selectedCalculationType.domain === 'DEFAULT' || selectedCalculationType.domain === 'TEMP' ) {
      calculation.calculate();
      calculation.status = CalculationStatus.Finished;
      if (fromSaveButton) {
        setCalculation(calculation);
      }
      updateFields(calculation);
      return calculation;

    } 
    
    // from calculation type
    else {
      calculation.calculationTypeId = selectedCalculationType.id;
      if (networkStatus?.connected) {
        const api = new Api();
        const clientInformation: IClientInformation|undefined = await getClientInformation();
        if (typeof clientInformation !== 'undefined') {
          api.token = clientInformation.accessToken;
        }
        const calculationDto = mapCalculationToCalculationDto(calculation, selectedCalculationType.domain, selectedCalculationType.originNr);
        const response = await api.doCalculation(calculationDto);
        if (typeof response !== 'undefined' && response.data && response.status === 200) {
          const calculationDtoFromApi: CalculationDto = response.data;
          const calculationFromApi: Calculation = mapCalculationDtoToCalculation(calculationDtoFromApi, selectedCalculationType.id);
          calculationFromApi.status = CalculationStatus.Finished;
          setCalculation(calculationFromApi);
          updateFields(calculationFromApi);
          return calculationFromApi;
        } 
        
        // api not reachable
        else {
          calculation.status = CalculationStatus.Pending;
          if (fromSaveButton) {
            setCalculation(calculation);
          }
          setShowCalculateOfflineAlert(true);
          return calculation;
        }
      } 
      
      // offline
      else {
        calculation.status = CalculationStatus.Pending;
        if (fromSaveButton) {
          setCalculation(calculation);
        }
        setShowCalculateOfflineAlert(true);
        return calculation;
      }
    }
  }

  const updateFields = (calculationUpdate: Calculation) => {
    setPresentValueOutput(convertNumberToGermanNumberFormat(calculationUpdate.presentValue.value));
    setPresentValueDifferenceOutput(getPresentValueDifferenceString(calculationUpdate));

    const elementToUpdate: ICalculatorInput = calculatorInputs[calculationUpdate.dissolveBy];
    if (elementToUpdate instanceof MoneyOrPercentage) {
      elementToUpdate.value = roundCommercially(calculationUpdate[calculationUpdate.dissolveBy].value, elementToUpdate.decimals);
      elementToUpdate.percent = calculatePercentageFromValue(calculatorInputs, elementToUpdate.id, rentAssessmentBase);
      if (elementToUpdate.inEuros) {
        elementToUpdate.raw = convertNumberToGermanNumberFormat(elementToUpdate.value);
      } else {
        elementToUpdate.raw = convertNumberToGermanNumberFormat(elementToUpdate.percent, 3);
      }
      elementToUpdate.rawChanged = true;
    } else if (elementToUpdate instanceof Term) {
      elementToUpdate.value = calculationUpdate[calculationUpdate.dissolveBy].value;
      elementToUpdate.raw = roundCommercially(elementToUpdate.value, 0);
      elementToUpdate.rawChanged = true;
    }
    let newCalculatorInputs: ICalculatorInputs = calculatorInputs;
    switch (calculationUpdate.dissolveBy.toString()) {
      case Field.NewPurchaseCosts:
        newCalculatorInputs = updateValuesAndPercentages(calculatorInputs, Field.NewPurchaseCosts, rentAssessmentBase);
        break;
      default:
        break;
    }
    setCalculatorInputs({...newCalculatorInputs});
  }

  const getPresentValueDifferenceString = (calculation: Calculation): string => {
    let presentValueDifferenceString: string = '';
    const presentValueDifference: number = Math.abs(calculation.presentValueOrigin.value - calculation.presentValue.value);
    presentValueDifferenceString = convertNumberToGermanNumberFormat(presentValueDifference);
    return presentValueDifferenceString;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle class="ion-hide-sm-up">Kalkulator</IonTitle>
          <IonTitle class="ion-hide-sm-down">LeasOne Kalkulator</IonTitle>
          <IonButtons slot="primary">
            <IonButton disabled={resetButtonDisabled} onClick={() => setShowResetCalculatorAlert(true)}>
              Zurücksetzen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} class={isPlatform('desktop') ? 'content-desktop' : ''}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large" class="ion-hide-sm-up" color="dark">Kalkulator</IonTitle>
            <IonTitle color="dark" size="large" class="ion-hide-sm-down">LeasOne Kalkulator</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonGrid class={isPlatform('desktop') ? 'ion-no-padding ion-padding-vertical grid-calculator' : 'ion-no-padding ion-padding-vertical ion-margin-bottom grid-calculator'}>
          
          <IonRow class="ion-align-items-start ion-justify-content-start ion-padding-vertical">
            <IonCol class="" size="12" sizeMd="6">
              <IonItem class="input-item" lines="full">
                <IonLabel>Kalkulationstyp</IonLabel>
                <IonSelect
                  key={'select-change-calculation-type'}
                  class=""
                  value={calculationTypeId} 
                  interface="alert"
                  cancelText="Abbrechen" 
                  okText="Ändern"
                  interfaceOptions={{
                    cssClass: 'alert-change-calculation-type',
                    header: 'Kalkulationstyp ändern?',
                    message: 'Wenn Sie den Kalkulationstyp ändern, gehen all Ihre bisherigen Eingaben verloren.'
                  }}
                  onIonChange={e => onCalculationTypeChange(e.detail.value)}>
                    {calculationTypes.map(calculationType => (
                    <IonSelectOption key={'calculation-type-' + calculationType.id} value={calculationType.id}>
                      {calculationType.description}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonItem>
              <IonItem class="input-item" lines="full">
                <IonLabel color="primary">Auflösen nach</IonLabel>
                <IonSelect 
                  key={'select-dissolve-by'}
                  class="select-dissolve-by"
                  value={calculation.dissolveBy} 
                  interface={isPlatform('ios') ? 'action-sheet' : 'popover'}
                  cancelText="Abbrechen"
                  interfaceOptions={isPlatform('ios') ? { header: 'Auflösen nach' } : {}}
                  onIonChange={e => onDissolveByChange(e.detail.value)}>
                    {Object.keys(DissolveBy).map(key => {
                      if (!calculatorInputs[DissolveBy[key]].disabled &&!calculatorInputs[DissolveBy[key]].hidden) {
                        return (
                          <IonSelectOption key={'dissolve-by-' + DissolveBy[key]} value={DissolveBy[key]}>
                            {calculatorInputs[DissolveBy[key]].label}
                          </IonSelectOption>
                        );
                      } else {
                        return null;
                      }
                    })}
                </IonSelect>
              </IonItem>
            </IonCol>

            <IonCol class="" size="12" sizeMd="6">
              <IonItem class="input-item" lines="none">
                <IonLabel>HP-Barwert</IonLabel>
                <IonText class="text-hp-present-value">
                  {presentValueOutput} €
                </IonText>
              </IonItem>
              <IonItem class="input-item" lines="full">
                <IonLabel color="medium">Barwert-Differenz</IonLabel>
                <IonText class="text-present-value-difference" color="medium">{presentValueDifferenceOutput} €</IonText>
              </IonItem>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-start ion-justify-content-start">
            <IonCol class="" size="12" sizeMd="12">
              <IonListHeader lines="none">
                <IonLabel>Basisdaten</IonLabel>
              </IonListHeader>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-start ion-justify-content-start">
            {generateInput(calculatorInputs.listPrice, true, 'decimal', 'next', 1)}
            {generateInput(calculatorInputs.discount, false, 'decimal', 'next', 2)}
            {generateInput(calculatorInputs.specialPayment, false, 'decimal', 'next', 3)}
            {generateInput(calculatorInputs.residualValue, false, 'decimal', 'next', 4)}
            {generateInput(calculatorInputs.term, undefined, 'numeric', 'next', 5)}
            {generateInput(calculatorInputs.installment, false, 'decimal', 'next', 6)}
          </IonRow>

          <IonRow class="ion-align-items-start ion-justify-content-start ion-margin-top">
            <IonCol class="" size="12" sizeMd="12">
              <IonListHeader lines="none">
                <IonLabel>Zusätzliche Daten</IonLabel>
              </IonListHeader>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-start ion-justify-content-start">
            {generateInput(calculatorInputs.newPurchaseCosts, true, 'decimal', 'next', 7)}
            {generateInput(calculatorInputs.presentValueMargin, false, 'decimal', 'next', 8)}
            {generateInput(calculatorInputs.interest, true, 'decimal', 'next', 9)}
          </IonRow>

          <IonRow class="ion-align-items-start ion-justify-content-start">
            {generateInput(calculatorInputs.paymentStep, undefined, undefined, undefined, 10, paymentStepColElement)}
            {generateInput(calculatorInputs.paymentMethod, undefined, undefined, undefined, 11, paymentMethodColElement)}
          </IonRow>

          <IonRow class="ion-align-items-start ion-justify-content-start ion-margin-top">
            <IonCol class="" size="12" sizeMd="12">
              <IonListHeader lines="none">
                <IonLabel>Aktionen</IonLabel>
              </IonListHeader>
            </IonCol>
          </IonRow>

          <IonRow class="ion-align-items-start ion-justify-content-start ion-margin-bottom">
            <IonCol class="" size="12" sizeMd="6">
              <IonButton
                expand="block"
                class="button-aktionen ion-margin-start"
                color="light"
                onClick={() => setShowSaveCalculationAlert(true)}>
                { calculationId === 0 ? 'Kalkulation speichern' : 'Als neue Kalkulation speichern' }
              </IonButton>
              <IonItem class="input-item ion-hide" lines="none">
                <IonText color="medium">Zurzeit sind keine Aktionen verfügbar.</IonText>
              </IonItem>
            </IonCol>
            <IonCol class="" size="12" sizeMd="6">
              <IonButton
                expand="block"
                class="button-aktionen ion-margin-end"
                color="light"
                hidden={calculationId === 0}
                onClick={() => onSaveCalculation(calculationDescription, false)}>
                Änderungen speichern
              </IonButton>
            </IonCol>
          </IonRow>

        </IonGrid>

        <IonFab vertical="bottom" horizontal="end" slot="fixed" class="">
          <IonFabButton onClick={async () => await calculate()} color="warning">
            <IonIcon icon={calculator} />
          </IonFabButton>
        </IonFab>

        <IonAlert
          isOpen={showResetCalculatorAlert}
          onDidDismiss={() => setShowResetCalculatorAlert(false)}
          cssClass="alert-reset-calculator"
          header="Kalkulator zurücksetzen?"
          message="Wenn Sie den Kalkulator zurücksetzen, gehen all Ihre bisherigen Eingaben verloren."
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel'
            },
            {
              text: 'Zurücksetzen',
              handler: () => {
                onResetCalculator(selectedCalculationType);
              }
            }
          ]} />

        <IonAlert
          isOpen={showSaveCalculationAlert}
          onDidDismiss={() => setShowSaveCalculationAlert(false)}
          cssClass=""
          header="Kalkulation speichern"
          message="Geben Sie eine Bezeichnung für die Kalkulation ein."
          inputs={[
            {
              name: 'calculationDescription',
              type: 'text',
              id: 'calculationDescription',
              value: calculationDescription,
              placeholder: 'Bezeichnung eingeben'
            }
          ]}
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel'
            },
            {
              text: 'Speichern',
              handler: (data) => {
                onSaveCalculation(data.calculationDescription);
              }
            }
          ]} />

        <IonAlert
          isOpen={showCalculateOfflineAlert}
          onDidDismiss={() => setShowCalculateOfflineAlert(false)}
          cssClass=""
          header={networkStatus?.connected ? 'Server nicht erreichbar' : 'Nicht mit dem Internet verbunden'}
          message={networkStatus?.connected ? 
            'Der Server ist aktuell nicht erreichbar. Möchten Sie die Kalkulation speichern, ' +
            'um sie auszuführen, wenn der Server wieder erreichbar ist?' : 
            'Sie sind aktuell nicht mit dem Internet verbunden. Möchten Sie die Kalkulation speichern, ' +
            'um sie auszuführen, wenn Sie wieder mit dem Internet verbunden sind?'
          }
          inputs={[
            {
              name: 'calculationDescription',
              type: 'text',
              id: 'calculationDescription',
              value: calculationDescription,
              placeholder: 'Bezeichnung eingeben'
            }
          ]}
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel'
            },
            {
              text: 'Speichern',
              handler: (data) => {
                onSaveCalculation(data.calculationDescription);
              }
            }
          ]} />

        <IonToast
          isOpen={showCalculationSavedToast}
          onDidDismiss={() => setShowCalculationSavedToast(false)}
          message="Kalkulation gespeichert"
          cssClass="toast-calculation-saved"
          position="top"
          color="success"
          duration={2500}
          buttons={[
            {
              side: 'end',
              icon: closeOutline,
              role: 'cancel'
            }
          ]}
        />
          
      </IonContent>
    </IonPage>
  );
};

export default Calculator;
