import React, { Fragment, useEffect, useRef, useState } from 'react';
import { IonItem, IonLabel, IonList, IonListHeader, isPlatform } from '@ionic/react';
import './CalculationTypesList.css';
import { CalculationType } from '../../models/calculation/CalculationType';

interface ICalculationTypesListProps {
	calculationTypes: CalculationType[];
	title: string;
	hideTitle: boolean;
}

const CalculationTypesList: React.FC<ICalculationTypesListProps> = (props) => {

	const [calculationTypes, setCalculationTypes] = useState<Array<CalculationType>>(props.calculationTypes);
	const [hideList, setHideList] = useState<boolean>(props.calculationTypes.length === 0);

	const list = useRef<any>(null);

	useEffect(() => {
		setCalculationTypes(props.calculationTypes.sort((a, b) => {
			return (a.description.toUpperCase() < b.description.toUpperCase()) ? -1 : (a.description.toUpperCase() > b.description.toUpperCase()) ? 1 : 0;
		}));
		setHideList(props.calculationTypes.length === 0)
	}, [props.calculationTypes]);

	return (
		<Fragment>
			<IonList ref={list} hidden={hideList} class={isPlatform('desktop') ? 'ion-padding-top list-desktop' : 'ion-padding-top'}>
				<IonListHeader hidden={props.hideTitle}>
					{props.title}
				</IonListHeader>
				{calculationTypes.map(calculationType => {
					return (
						<IonItem key={calculationType.domain + calculationType.id} detail={false}>
							<IonLabel>
								<h2>{calculationType.description}</h2>
							</IonLabel>
							<div tabIndex={calculationType.id}></div>
						</IonItem>
					);
				})}
			</IonList>
		</Fragment>
	);
};

export default CalculationTypesList;
