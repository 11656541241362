import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { IonItem, IonLabel, IonSelect, IonSelectOption, IonToast, isPlatform} from '@ionic/react';
import { ISelectSelectOptions } from '../../models/options/ISelectSelectOptions';
import './SelectInput.css';
import { Field } from '../../enums/Field';

interface ISelectInputProps { 
	id: Field;
	label: string;
	value: string|number;
	valueChanged: boolean;
	selectOptions: ISelectSelectOptions[];
	isFocussed: boolean;
	tabIndex: number;
	disabled: boolean;
	onChange: (id: Field) => (e: any) => void;
	focusPreviousOrNextCalculationInput: (id: Field, e: KeyboardEvent) => void;
	resetValueChanged: (id: Field) => void;
	resetFocus: (id: Field) => void;
}

const SelectInput: React.FC<ISelectInputProps> = (props) => {

	const inputElement = useRef<any>(null);
	const [value, setValue] = useState<string|number>(props.value);
	const [showDisabledMessage, setShowDisabledMessage] = useState<boolean>(false);

	useEffect(() => {
		if (props.isFocussed) {
			inputElement.current.focus();
			props.resetFocus(props.id);
		}
	})

	useEffect(() => {
		if (props.valueChanged) {
			setValue(props.value);
			props.resetValueChanged(props.id);
		}
	}, [props])
	
	const disableDefaultTab = async (e: KeyboardEvent) => {
    if ((e.key === 'Tab' && !e.shiftKey) || (e.key === 'Tab' && e.shiftKey)) {
      e.preventDefault();
    }
	}

	const onItemClick = async (e: React.MouseEvent) => {
		if (e.target === e.currentTarget) {
			inputElement.current.focus();
		}
		if (props.disabled || props.selectOptions.length < 2) {
			// setShowDisabledMessage(true);
		}
	}

	const onChange = (e: any) => {
		if (!props.valueChanged) {
			setValue(e.detail.value);
			props.onChange(props.id)(e.detail.value);
		}
	}
	
  return (
		<React.Fragment>
			<IonItem key={props.id} class="input-item" lines="full" onClick={e => onItemClick(e)}>
				<IonLabel>{props.label}</IonLabel>
				<IonSelect ref={inputElement} 
					name={props.id} 	
					id={props.id}
					value={value} 
					disabled={props.disabled || props.selectOptions.length < 2} 
					interface={isPlatform('ios') ? 'action-sheet' : 'popover'}
					cancelText="Abbrechen" 
					interfaceOptions={isPlatform('ios') ? { header: props.label } : {}}
					onIonChange={onChange}
					onKeyUp={e => props.focusPreviousOrNextCalculationInput(props.id, e)} 
					onKeyDown={e => disableDefaultTab(e)}>
					{props.selectOptions.map(selectOption => (
						<IonSelectOption key={selectOption.value} value={selectOption.value}>{selectOption.label}</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
			<IonToast
				isOpen={showDisabledMessage}
				onDidDismiss={() => setShowDisabledMessage(false)}
				header="Fixer Wert"
				message={props.label + ' ist fix und kann nicht geändert werden.'}
				color="medium"
				position="top"
				duration={3000}
			/>
		</React.Fragment>
  );
};

export default SelectInput;
