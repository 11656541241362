import axios from 'axios';
import { ActivationDto } from '../models/dtos/ActivationDto';
import { CalculationDto } from '../models/dtos/CalculationDto';
import { ClientDto } from '../models/dtos/ClientDto';
import { TokenDto } from '../models/dtos/TokenDto';

export class Api {

	endpoint: string = '';
	clientId: string = '';
	clientSecret: string = '';
	client: any|null;
	headers: Object|undefined;
	token: string|null;

	constructor() {
		if (typeof process.env.REACT_APP_API_ENDPOINT !== 'undefined') {
			this.endpoint = process.env.REACT_APP_API_ENDPOINT;
		}
		this.client = null;
		this.token = null;
	}

	init = () => {
		if (typeof process.env.REACT_APP_API_ENDPOINT !== 'undefined') {
			this.endpoint = process.env.REACT_APP_API_ENDPOINT;
		}
		this.headers = {
			'Accept': 'application/json'
		}
		if (this.token !== '' && this.token !== null) {
			this.headers['Authorization'] = 'Bearer ' + this.token;
		}

		this.client = axios.create({
			baseURL: this.endpoint,
			timeout: 30000,
			headers: this.headers
		})

		return this.client;
	}

	doGetRequest = async (url: string, params?: Object) => {
		try {
			const response = await this.init().get(url, { 
				params: params 
			});
			return response;
		} catch (error) {
			if (error.response) {
				console.error('Error', 'Request failed with status ' + error.response.status);
			} else if (error.request) {
				console.error('Error', 'Requested server not available');
			} else {
				console.error('Error', error.message);
			}
		}
	}

	doPostRequest = async (url: string, data?: Object) => {
		try {
			const response = await this.init().post(url, data);
			return response;
		} catch (error) {
			if (error.response) {
				console.error('Error', 'Request failed with status ' + error.response.status);
			} else if (error.request) {
				console.error('Error', 'Requested server not available');
			} else {
				console.error('Error', error.message);
			}
		}
	}

	// Ping
	getPing = async () => {
		const url: string = '/ping';
		return await this.doGetRequest(url);
	}

	// Client
	registerClient = async (body: ClientDto) => {
		const url: string = '/clients';
		return await this.doPostRequest(url, body);
	}

	// Token
	getNewAccessToken = async (body: TokenDto) => {
		const url: string = '/token';
		return await this.doPostRequest(url, body);
	}

	// Activation
	authorizeActivation = async (body: ActivationDto) => {
		const url: string = '/activations/authorize';
		return await this.doPostRequest(url, body);
	}

	// Calculation Type
	getCalculationTypes = async () => {
		const url: string = '/calculation-types'
		return await this.doGetRequest(url);
	}

	// Calculation
	doCalculation = async (body: CalculationDto) => {
		const url: string = '/calculations/calculate'
		return await this.doPostRequest(url, body);
	}

}
