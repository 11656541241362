// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import { Calculation } from '../models/calculation/Calculation';
import CalculationsList from '../components/calculationsList/CalculationsList';
import './SavedCalculations.css';
import { DB } from '../db/DB';
import { Reference } from '../enums/Reference';
import { getCalculationsFromDB, storeCalculationInDB, updateCalculationInDB } from '../utils/dbUtils';
import { close, refresh } from 'ionicons/icons';
import { CalculationStatus } from '../enums/CalculationStatus';

interface ISavedCalculationsProps {
  reloadCalculations: boolean;
  resetReloadCalculations: () => void;
}

const SavedCalculations: React.FC<ISavedCalculationsProps> = (props) => {

  const [debug] = useState<boolean>(false);
  const [debugCalculations] = useState<Array<Calculation>>([
    new Calculation({
      pinned: true, 
      presentValue: {
        value: 10000,
        reference: Reference.None
      },
      calculationTypeId: 1
    })
  ]);
  const [calculations, setCalculations] = useState<Array<Calculation>>([]);
  const [lastCalculationDeleted, setLastCalculationDeleted] = useState<Calculation|undefined>(undefined);
  const [showCalculationDeletedToast, setShowCalculationDeletedToast] = useState<boolean>(false);
  const [noCalculations, setNoCalculations] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showPendingCalculations, setShowPendingCalculations] = useState<boolean>(true);
  const [pendingCalculations, setPendingCalculations] = useState<number>(0);

  const refresher = useRef<any>(null);
  const pendingCalculationsElement = useRef<any>(null);

  useEffect(() => {
    if (debug) {
      const db = new DB();
      debugCalculations.forEach(calculation => {
        storeCalculation(db, calculation);
      })
    }

    loadCalculations();
    // eslint-disable-next-line
  }, [debug]);

  useEffect(() => {
    if (props.reloadCalculations) {
      loadCalculations();
      props.resetReloadCalculations();
    }
  }, [props]);
  
  const storeCalculation = async (db: DB, calculation: Calculation) => {
    await storeCalculationInDB(db, calculation);
  }

  const loadCalculations = async () => {
    const db = new DB();
    try {
      const newCalculations: Calculation[] = await getCalculationsFromDB(db);
      setCalculations(newCalculations);
      setNoCalculations(newCalculations.filter(c => c.deleted === false && c.status === CalculationStatus.Finished).length === 0);

      setShowPendingCalculations(newCalculations.filter(c => c.status === CalculationStatus.Pending).length > 0);
      setPendingCalculations(newCalculations.filter(c => c.status === CalculationStatus.Pending).length);
    } catch (error) {
      console.log(error.stack);
    }
  }

  const doRefresh = async (fromButton: boolean = false) => {
    if (fromButton) {
      setShowLoading(true);
    }
    setTimeout(async () => {
      await loadCalculations();
      refresher.current.complete();
      if (fromButton) {
        setShowLoading(false);
      }
    }, 500);
  }

  const handlePinClicked = async (id: number) => {
    const index: number|undefined = calculations.findIndex(c => c.id === id);
    if (index >= 0) {
      const db = new DB();
      const newCalculation: Calculation = calculations[index];
      newCalculation.pinned = !newCalculation.pinned;
      await storeCalculationInDB(db, newCalculation);
      setCalculations(
        calculations.map((calculation) => {
          return calculation.id === id ? newCalculation : calculation;
        })
      );
    }
  }

  const handleDeleteClicked = async (id: number) => {
    const index: number|undefined = calculations.findIndex(c => c.id === id);
    if (index >= 0) {
      const db = new DB();
      const calculationToDelete: Calculation = calculations[index];
      calculationToDelete.deleted = true;
      calculationToDelete.chgDate = new Date();
      await updateCalculationInDB(db, calculationToDelete);
      setCalculations(
        calculations.map((calculation) => {
          return calculation.id === id ? calculationToDelete : calculation;
        })
      );
      setLastCalculationDeleted(calculationToDelete);
      setShowCalculationDeletedToast(true);
      setNoCalculations(calculations.filter(c => c.deleted === false).length === 0);
    }
  }

  const handleUndoDeleteClicked = async () => {
    if (typeof lastCalculationDeleted !== 'undefined') {
      const db = new DB();
      lastCalculationDeleted.deleted = false;
      lastCalculationDeleted.chgDate = new Date();
      await updateCalculationInDB(db, lastCalculationDeleted);
      setCalculations(
        calculations.map((calculation) => {
          return calculation.id === lastCalculationDeleted.id ? lastCalculationDeleted : calculation;
        })
      );
      clearLastCalculationDeleted();
    }
  }

  const clearLastCalculationDeleted = () => {
    setShowCalculationDeletedToast(false);
    setLastCalculationDeleted(undefined);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Gespeichert</IonTitle>
          <IonButtons slot="primary">
            <IonButton onClick={() => doRefresh(true)} class={isPlatform('desktop') ? '' : 'ion-hide'}>
              <IonIcon slot="icon-only" icon={refresh}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} class={isPlatform('desktop') ? 'content-desktop' : ''}>
        {/* {<IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Gespeichert</IonTitle>
          </IonToolbar>
        </IonHeader>} */}

        <IonRefresher ref={refresher} slot="fixed" onIonRefresh={() => doRefresh()}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonList ref={pendingCalculationsElement} hidden={!showPendingCalculations} class={isPlatform('desktop') ? 'ion-padding-top list-desktop' : 'ion-padding-top'}>
          <IonItem lines="none" color="light" class="item-pending-calculations ion-margin-top ion-margin-horizontal">
            <IonLabel>{pendingCalculations} ausstehende {pendingCalculations === 1 ? 'Kalkulation' : 'Kalkulationen'}
            </IonLabel>
            <IonButton slot="end" fill="clear" onClick={() => setShowPendingCalculations(false)}>
              <IonIcon slot="icon-only" icon={close} color="dark"/>
            </IonButton>
          </IonItem>
        </IonList>

        <CalculationsList calculations={calculations.filter((calculation) => {
          if (calculation.pinned && !calculation.deleted&& calculation.status === CalculationStatus.Finished) {
            return calculation;
          } else {
            return null;
          }
        })} 
          title="Angeheftet" 
          icon="unpin"
          handlePinClicked={handlePinClicked}
          handleDeleteClicked={handleDeleteClicked}
        />

        <CalculationsList calculations={calculations.filter((calculation) => {
          if (!calculation.pinned && !calculation.deleted && calculation.status === CalculationStatus.Finished) {
            return calculation;
          } else {
            return null;
          }
        })} 
          title="Kalkulationen" 
          icon="pin"
          handlePinClicked={handlePinClicked}
          handleDeleteClicked={handleDeleteClicked}
        />

        <IonItem hidden={!noCalculations} lines="none">
          <IonLabel color="medium" class="ion-text-wrap ion-text-center">Keine gespeicherten Kalkulationen vorhanden</IonLabel>
        </IonItem>

        <IonToast 
          isOpen={showCalculationDeletedToast}
          onDidDismiss={() => clearLastCalculationDeleted()}
          cssClass="toast-calculation-deleted"
          message="Kalkulation gelöscht"
          position="top"
          color="light"
          duration={5000}
          buttons={[
            {
              side: 'end',
              text: 'Rückgängig',
              handler: async () => {
                await handleUndoDeleteClicked();
              }
            }
          ]}
        />

        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Aktualisieren...'}
          duration={1000}
        />

      </IonContent>
    </IonPage>
  );
};

export default SavedCalculations;
