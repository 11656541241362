// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import './CalculationDetail.css';
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonListHeader, IonPage, IonRow, IonText, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { Calculation } from '../models/calculation/Calculation';
import { RouteComponentProps } from 'react-router';
import { DB } from '../db/DB';
import { mapDBCalculationToCalculation } from '../utils/mapperUtils';
import { convertNumberToGermanNumberFormat } from '../utils/calculatorUtils';
import { getCalculationByIdFromDB, storeCalculationInDB } from '../utils/dbUtils';
import { arrowBack, chevronBack, trashOutline } from 'ionicons/icons';
import { CalculationType } from '../models/calculation/CalculationType';
import { ICalculationField } from '../models/calculation/ICalculationField';
import { getPaymentMethodLabel, getPaymentStepLabel } from '../utils/transformUtils';

interface ICalculationDetailProps extends RouteComponentProps<{
	id: string;
}> {
	calculationTypes: CalculationType[];
	loadCalculationInCalculator: (calculation: Calculation) => void;
	doReloadCalculations: () => void;
}

const CalculationDetail: React.FC<ICalculationDetailProps> = (props) => {

	const [calculation, setCalculation] = useState<Calculation>(new Calculation());
	const [calculationType, setCalculationType] = useState<CalculationType>(new CalculationType());
	const [pinned, setPinned] = useState<boolean>(false);
	const [calculationDescription, setCalculationDescription] = useState<string>('');
	const [showDeleteCalculationAlert, setShowDeleteCalculationAlert] = useState<boolean>(false);
	const [showChangeCalculationDescriptionAlert, setShowChangeCalculationDescriptionAlert] = useState<boolean>(false);

	useEffect(() => {
    const db = new DB();
		getCalculation(db);
		// eslint-disable-next-line
	}, []);
	
	const getCalculation = async (db: DB) => {
		const calculationFromDB = await getCalculationByIdFromDB(db, parseInt(props.match.params.id));
		if (typeof calculationFromDB !== 'undefined') {
			const mappedCalculation = mapDBCalculationToCalculation(calculationFromDB)
			setCalculation(mappedCalculation);
			setPinned(mappedCalculation.pinned);
			setCalculationDescription(mappedCalculation.description);
			const index = props.calculationTypes.findIndex(c => c.id === mappedCalculation.calculationTypeId);
			if (index > 0) {
				setCalculationType(props.calculationTypes[index])
			}
		} else {
			props.history.push('/calculations');
		}
	}

	const handlePinClicked = async () => {
		const db = new DB();
		calculation.pinned = !calculation.pinned;
		setCalculation(calculation);
		setPinned(calculation.pinned);
		await storeCalculationInDB(db, calculation);
		props.doReloadCalculations();
  }

	const handleDeleteClicked = async () => {
		const db = new DB();
		calculation.deleted = true;
		await storeCalculationInDB(db, calculation);
		props.doReloadCalculations();
		props.history.push('/calculations');
	}

	const onChangeDescription = async (newDescription: string) => {
		const db = new DB();
		calculation.description = newDescription;
		setCalculationDescription(calculation.description);
		await storeCalculationInDB(db, calculation);
		props.doReloadCalculations();
	}
	
	const loadCalculationInCalculator = () => {
		props.loadCalculationInCalculator(calculation);
	}

	const generateField = (calculationField: ICalculationField, label: string, hidden: boolean) => {
		return (
			<IonCol class="" size="12" sizeMd="6" hidden={hidden}>
				<IonItem lines="full">
					<IonLabel>{label}</IonLabel>
					<IonText class="ion-padding-end">
						{convertNumberToGermanNumberFormat(calculationField.value)} €
					</IonText>
				</IonItem>
			</IonCol>
		);
	}

	return (
		<IonPage>
      <IonHeader>
        <IonToolbar>
					<IonButtons slot="start">
						<IonButton routerLink="/calculations" routerDirection="back" onClick={() => props.doReloadCalculations()}>
              <IonIcon slot="icon-only" ios={chevronBack} md={arrowBack}/>
            </IonButton>
					</IonButtons>
          <IonTitle>{calculation.description}</IonTitle>
					<IonButtons slot="primary">
            <IonButton onClick={() => setShowDeleteCalculationAlert(true)}>
              <IonIcon slot="icon-only" color="danger" icon={trashOutline}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} class={isPlatform('desktop') ? 'content-desktop' : ''}>
				<IonGrid class="ion-no-padding ion-padding-vertical">
          
          <IonRow class="ion-align-items-start ion-justify-content-start ion-padding-vertical">
            <IonCol class="" size="12" sizeMd="6">
							<IonItem lines="full">
                <IonLabel>Kalkulationstyp</IonLabel>
								<IonText class="ion-padding-end">
                  {calculationType.description}
                </IonText>
							</IonItem>
							<IonItem lines="full">
                <IonLabel>Aufgelöst nach</IonLabel>
								<IonText class="ion-padding-end">
                  {calculationType[calculation.dissolveBy].label}
                </IonText>
							</IonItem>
						</IonCol>
						<IonCol class="" size="12" sizeMd="6">
							<IonItem lines="full">
                <IonLabel>HP-Barwert</IonLabel>
								<IonText class="ion-padding-end">
                  {convertNumberToGermanNumberFormat(calculation.presentValue.value)} €
                </IonText>
							</IonItem>
						</IonCol>
					</IonRow>

					<IonRow class="ion-align-items-start ion-justify-content-start">
            <IonCol class="" size="12" sizeMd="12">
              <IonListHeader lines="none">
                <IonLabel>Basisdaten</IonLabel>
              </IonListHeader>
            </IonCol>
          </IonRow>

					<IonRow class="ion-align-items-start ion-justify-content-start ion-margin-bottom">
						{generateField(calculation.listPrice, "Listenpreis", false)}
						{generateField(calculation.discount, "Rabatt", false)}
						{generateField(calculation.specialPayment, "Sonderzahlung", calculationType.specialPayment.hidden)}
						{generateField(calculation.residualValue, "Restwert", calculationType.residualValue.hidden)}

						<IonCol class="" size="12" sizeMd="6" hidden={calculationType.term.hidden}>
							<IonItem lines="full">
                <IonLabel>Laufzeit</IonLabel>
								<IonText class="ion-padding-end">
                  {convertNumberToGermanNumberFormat(calculation.term.value, 0)} Monat(e)
                </IonText>
							</IonItem>
						</IonCol>

						{generateField(calculation.installment, "Rate", calculationType.installment.hidden)}
						
					</IonRow>

					<IonRow class="ion-align-items-start ion-justify-content-start">
            <IonCol class="" size="12" sizeMd="12">
              <IonListHeader lines="none">
                <IonLabel>Zusätzliche Daten</IonLabel>
              </IonListHeader>
            </IonCol>
          </IonRow>

					<IonRow class="ion-align-items-start ion-justify-content-start">
						{generateField(calculation.newPurchaseCosts, "NAK", false)}
						{generateField(calculation.presentValueMargin, "Marge", calculationType.presentValueMargin.hidden)}
						
						<IonCol class="" size="12" sizeMd="6" hidden={calculationType.interest.hidden}>
							<IonItem lines="full">
                <IonLabel>Zins</IonLabel>
								<IonText class="ion-padding-end">
                  {convertNumberToGermanNumberFormat(calculation.interest.value, 3)} %
                </IonText>
							</IonItem>
						</IonCol>
					</IonRow>

					<IonRow class="ion-align-items-start ion-justify-content-start ion-margin-bottom">
						<IonCol class="" size="12" sizeMd="6" hidden={calculationType.paymentStep.hidden}>
							<IonItem lines="full">
                <IonLabel>Zahlweise</IonLabel>
								<IonText class="ion-padding-end">
                  {getPaymentStepLabel(calculation.paymentStep)}
                </IonText>
							</IonItem>
						</IonCol>
						<IonCol class="" size="12" sizeMd="6" hidden={calculationType.paymentStep.hidden}>
							<IonItem lines="full">
                <IonLabel>Zahlmodus</IonLabel>
								<IonText class="ion-padding-end">
								{getPaymentMethodLabel(calculation.paymentMethod)}
                </IonText>
							</IonItem>
						</IonCol>
					</IonRow>

					<IonRow class="ion-align-items-start ion-justify-content-start">
            <IonCol class="" size="12" sizeMd="12">
              <IonListHeader lines="none">
                <IonLabel>Aktionen</IonLabel>
              </IonListHeader>
            </IonCol>
          </IonRow>

					<IonRow class="ion-align-items-start ion-justify-content-start ion-margin-bottom">
            <IonCol class="" size="12" sizeMd="4">
							<IonButton
								key="button-load-in-calculator"
                expand="block"
                class="button-aktionen ion-margin-start"
								color="light"
								routerLink="/calculator" 
								routerDirection="root"
                onClick={() => loadCalculationInCalculator()}>
                In Kalkulator laden
              </IonButton>
						</IonCol>
						<IonCol class="" size="12" sizeMd="4">
							<IonButton
								key="button-change-description"
                expand="block"
                class="button-aktionen"
                color="light"
                onClick={() => setShowChangeCalculationDescriptionAlert(true)}>
                Bezeichnung ändern
              </IonButton>
						</IonCol>
						<IonCol class="" size="12" sizeMd="4">
							<IonButton
								key="button-pin-or-unpin"
                expand="block"
                class="button-aktionen ion-margin-end"
                color="light"
                onClick={async () => await handlePinClicked()}>
                	{pinned ? 'Nicht mehr anheften' : 'Anheften'}
              </IonButton>
						</IonCol>
					</IonRow>
				</IonGrid>

				<IonAlert
					key="alert-delete-calculation"
          isOpen={showDeleteCalculationAlert}
          onDidDismiss={() => setShowDeleteCalculationAlert(false)}
          cssClass="alert-delete-calculation"
          header="Kalkulation löschen?"
          message="Sind Sie sicher, dass Sie diese Kalkulation löschen möchten?"
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel'
            },
            {
              text: 'Löschen',
              handler: () => {
                handleDeleteClicked();
							}
            }
          ]} />

				<IonAlert
					key="alert-change-calculation-description"
          isOpen={showChangeCalculationDescriptionAlert}
          onDidDismiss={() => setShowChangeCalculationDescriptionAlert(false)}
          cssClass="alert-change-calculation-description"
					header="Bezeichnung ändern"
					message="Geben Sie eine Bezeichnung für die Kalkulation ein."
					inputs={[
            {
              name: 'calculationDescription',
              type: 'text',
							id: 'calculationDescription',
              value: calculationDescription,
							placeholder: 'Bezeichnung eingeben'
            }
          ]}
          buttons={[
            {
              text: 'Abbrechen',
              role: 'cancel'
            },
            {
              text: 'Speichern',
              handler: (data) => {
                onChangeDescription(data.calculationDescription);
							}
            }
          ]} />

			</IonContent>
    </IonPage>
	);
};

export default CalculationDetail;
