import { CalculatorInput } from './CalculatorInput';
import { ITermOptions } from '../options/ITermOptions';

export class Term extends CalculatorInput {
  raw: number;
  rawChanged: boolean;
  value: number;
  min: number;
  max: number;
  dissolveBy: boolean;

  constructor(options: ITermOptions) {
    super(options);
    this.raw = options.raw;
    this.rawChanged = options.rawChanged;
    this.value = options.value;
    this.min = options.min;
    this.max = options.max;
    this.dissolveBy = options.dissolveBy;
  }
}
