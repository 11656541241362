import { Api } from '../api/Api';
import { ClientDto } from '../models/dtos/ClientDto';
import { TokenDto } from '../models/dtos/TokenDto';

const CLIENT_LOCAL_STORAGE_KEY = 'client_information';

export interface IClientInformation {
	clientId: string;
	clientSecret: string;
	accessToken: string;
	refreshToken: string;
	topic: string;
}

export const isClientRegistered = async (): Promise<boolean> => {
	const clientInformation: IClientInformation|undefined = await getClientInformation();
	if (typeof clientInformation !== 'undefined') {
		return true;
	} else {
		return false;
	}
}

export const registerClient = async (clientId: string|undefined, topic: string|undefined): Promise<boolean> => {
	if (typeof clientId === 'undefined') {
		clientId = generateUuidv4();
	}
	if (typeof topic === 'undefined') {
		topic = 'Unbenanntes Gerät';
	}

	const api = new Api();
	const clientDto = new ClientDto();
	clientDto.clientId = clientId;
	clientDto.topic = topic;
	const response = await api.registerClient(clientDto);
	if (typeof response !== 'undefined' && response.data) {
		const clientInformation: IClientInformation = {
			clientId: response.data.clientId,
			clientSecret: response.data.clientSecret,
			accessToken: response.data.accessToken,
			refreshToken: response.data.refreshToken,
			topic: response.data.topic
		}
		storeClientInformation(clientInformation);
		return true;
	} else {
		return false;
	}
}

export const getNewAccessToken = async (clientInformation: IClientInformation) => {
	const api = new Api();
	try {
		const response = await api.getPing();
	if (typeof response !== 'undefined') {
		const tokenDto = new TokenDto();
		tokenDto.clientId = clientInformation.clientId;
		tokenDto.clientSecret = clientInformation.clientSecret;
		tokenDto.refreshToken = clientInformation.refreshToken;
		const response = await api.getNewAccessToken(tokenDto);
		if (typeof response !== 'undefined' && response.data) {
			clientInformation.accessToken = response.data.accessToken;
			if (response.data.refreshToken !== null) {
				clientInformation.refreshToken = response.data.refreshToken;
			}
			if (response.data.topic !== null) {
				clientInformation.topic = response.data.topic;
			}
			storeClientInformation(clientInformation);
		}
	}
	} catch (error) {
		
	}
}

export const storeClientInformation = (clientInformation: IClientInformation) => {
	const clientInformationToStore: string = btoa(JSON.stringify(clientInformation));
	localStorage.setItem(CLIENT_LOCAL_STORAGE_KEY, clientInformationToStore);
}

export const getClientInformation = async (steps: number = 1): Promise<IClientInformation|undefined> => {
	const clientInformationFromLocalStorage: string|null = localStorage.getItem(CLIENT_LOCAL_STORAGE_KEY);
	if (clientInformationFromLocalStorage !== null) {
		const clientInformation: IClientInformation = JSON.parse(atob(clientInformationFromLocalStorage));
		if (checkIfTokenExpired(clientInformation.accessToken)) {
			if (steps > 0) {
				await getNewAccessToken(clientInformation);
				return getClientInformation(steps - 1);
			}
		}
		return clientInformation;
	} else {
		return undefined;
	}
}

export const checkIfTokenExpired = (token: string): boolean => {
	const split = token.split('.');
	if (split.length < 2) {
		return true;
	}

	try {
		const payload = JSON.parse(atob(token.split('.')[1]));
		if (payload && payload.exp && Number.isFinite(payload.exp)) {
			if (Date.now() >= payload.exp * 1000) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	} catch (error) {
		return true;
	}
}

const generateUuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 || 0, v = c === 'x' ? r : ((r && 0x3) || 0x8);
    return v.toString(16);
  });
}
