import { PaymentMethod } from '../enums/PaymentMethod';
import { PaymentStep } from '../enums/PaymentStep';

const MAX_ERROR: number = 0.0000001;

/**
 * Rounds a given value commercially.
 * 
 * @param {number} value Value to round
 * @param {number} decimals Number of decimals, default: 5
 * @returns {number} roundedValue
 */
export const roundCommercially = (value: number, decimals: number = 5): number => {
	if (decimals === 0) {
		return Math.round(value);
	} else if (decimals > 0 && decimals < 11) {
		const decimalHelper: number = Math.pow(10, decimals);
		const decimalNumber = Math.round((value + Number.EPSILON) * decimalHelper) / decimalHelper;
		return decimalNumber;
	} else {
		return 0;
	}
}

/**
 * Gets payment periods from payment step.
 * 
 * @param {PaymentStep} paymentStep Payment step
 * @returns {number} periods
 */
export const getPeriodsFromPaymentStep = (paymentStep: PaymentStep): number => {
  return 12 / paymentStep;
}

/**
 * Gets periodic interest from interest and payment step
 * 
 * @param {number} interest interest
 * @param {PaymentStep} paymentStep Payment step
 * @returns {number} periodicInterest
 */
export const getPeriodicInterest = (interest: number, paymentStep: PaymentStep): number => {
  return interest * paymentStep / 12;
}

/**
 * Calculates the present value based on the final payment, installment
 * periodic interest in advance or in arrears.
 * 
 * @param {number} finalPayment Final payment 
 * @param {number} installment Installment 
 * @param {number} periodicInterest Interest 
 * @param {PaymentMethod} paymentMethod Payment method 
 * @param {number} periods Periods 
 * @returns {number} presentValue
 */
export const calculatePresentValue = (finalPayment: number, installment: number, periodicInterest: number, paymentMethod: PaymentMethod, periods: number): number => {
	let presentValue: number = 0;
  if (paymentMethod === PaymentMethod.InAdvance) {
    presentValue = installment * calculateUniformSeriesPresentValue(periodicInterest, periods) * (1 + periodicInterest / 100) + finalPayment * calculateSinglePaymentPresentValue(periodicInterest, periods);
  } else {
    presentValue = installment * calculateUniformSeriesPresentValue(periodicInterest, periods) + finalPayment * calculateSinglePaymentPresentValue(periodicInterest, periods);
  }
  return presentValue;
}

/**
 * Calculates the term depending on the present value, final payment,
 * installment, periodic interest in advance or in arrears.
 * 
 * @param {number} presentValue Present value
 * @param {number} finalPayment Final payment
 * @param {number} installment Installment
 * @param {number} periodicInterest Interest
 * @param {number} periods Periods
 * @param {PaymentMethod} paymentMethod Payment method
 * @param {number} step Step
 * @returns {number} term
 */
export const calculateTermIterative = (presentValue: number, finalPayment: number, installment: number, periodicInterest: number, paymentMethod: PaymentMethod, periods: number, step: number): number => {
  if (step === 0) {
		return periods;
	}

	let i: number = periodicInterest / 100;
	let s: number = Math.pow(1 + i, -periods);
	let u: number = (1 - s) / i;
	let installmentInterestReciprocal: number = installment / i;

	let numerator: number;
	let denominator: number;
	if (paymentMethod === PaymentMethod.InAdvance) {
		numerator = installment * u * (1 + i) + (finalPayment * s) - presentValue;
		denominator = ((1 + i) * installmentInterestReciprocal - finalPayment) * s * Math.log(1 + i);
	} else {
		numerator = installment * u + finalPayment * s - presentValue;
		denominator = (installmentInterestReciprocal - finalPayment) * s * Math.log(1 + i);
	}
	let periodsNew: number = periods - (numerator / denominator);

	let relativeError = Math.abs((periods - periodsNew) / periodsNew);
	if (relativeError < MAX_ERROR) {
		return periodsNew;
	}

	return calculateTermIterative(presentValue, finalPayment, installment, periodicInterest, paymentMethod, periodsNew, step - 1);
}

/**
 * Calculates the interest depending on the present value, 
 * final payment, installment, term in advance or in arrears.
 * 
 * @param {number} presentValue Present value
 * @param {number} finalPayment Final payment
 * @param {number} installment Installment
 * @param {PaymentMethod} paymentMethod 
 * @param {number} periods 
 * @param {number} step 
 * @param {number} periodicInterest 
 * @returns {number} interest
 */
export const calculateInterestIterative = (presentValue: number, finalPayment: number, installment: number, paymentMethod: PaymentMethod, periods: number, step: number, periodicInterest: number): number => {
	if (step === 0) {
		return periodicInterest;
	}

	let s: number = Math.pow(1 + periodicInterest, -periods);
	let sMin: number = Math.pow(1 + periodicInterest, -periods - 1);
	let u: number = (1 - s) / periodicInterest;
	let interestReciprocal: number = 1 / periodicInterest;
	let installmentInterestReciprocal: number = installment / periodicInterest;

	let numerator: number;
	let denominator: number;
	if (paymentMethod === PaymentMethod.InAdvance) {
		numerator = installment * u * (1 + periodicInterest) + finalPayment * s - presentValue;
		denominator = periods * sMin * (installment * (interestReciprocal + 1) - finalPayment) - installment * interestReciprocal * u;
	} else {
		numerator = installment * u + finalPayment * s - presentValue;
		denominator = periods * sMin * (installmentInterestReciprocal - finalPayment) - installmentInterestReciprocal * u;
	}
	let interestNew: number = periodicInterest - numerator / denominator;

	let relativeError = Math.abs((periodicInterest - interestNew) / interestNew);
	if (relativeError < MAX_ERROR) {
		return interestNew;
	}

	return calculateInterestIterative(presentValue, finalPayment, installment, paymentMethod, periods, step - 1, interestNew);
}

/**
 * Calculates the installment depending on the present value, 
 * final payment, periodic interest in advance or in arrears.
 * 
 * @param {number} presentValue Present value
 * @param {number} finalPayment Final payment
 * @param {number} periodicInterest Interest
 * @param {PaymentMethod} paymentMethod Payment method
 * @param {number} periods Periods
 * @returns {number} installment
 */
export const calculateInstallment = (presentValue: number, finalPayment: number, periodicInterest: number, paymentMethod: PaymentMethod, periods: number): number => {
	let installment: number = 0;
  if (paymentMethod === PaymentMethod.InAdvance) {
    installment = (presentValue - finalPayment * calculateSinglePaymentPresentValue(periodicInterest, periods)) / (calculateUniformSeriesPresentValue(periodicInterest, periods) * (1 + periodicInterest / 100));
  } else {
    installment = (presentValue - finalPayment * calculateSinglePaymentPresentValue(periodicInterest, periods)) / (calculateUniformSeriesPresentValue(periodicInterest, periods));
  }
  return installment;
}

/**
 * Calculates the final payment depending on the present value, 
 * installment, periodic interest in advance or in arrears.
 * 
 * @param {number} presentValue present value
 * @param {number} installment Installment
 * @param {number} periodicInterest Interest
 * @param {PaymentMethod} paymentMethod Payment method
 * @param {number} periods Periods
 * @returns {number} finalPayment
 */
export const calculateFinalPayment = (presentValue: number, installment: number, periodicInterest: number, paymentMethod: PaymentMethod, periods: number): number => {
	let finalPayment: number = 0;
	if (paymentMethod === PaymentMethod.InAdvance) {
		finalPayment = (presentValue - installment * calculateUniformSeriesPresentValue(periodicInterest, periods) * (1 + periodicInterest / 100)) / calculateSinglePaymentPresentValue(periodicInterest, periods);
	} else {
		finalPayment = (presentValue - installment * calculateUniformSeriesPresentValue(periodicInterest, periods)) / calculateSinglePaymentPresentValue(periodicInterest, periods);
	}
	return finalPayment;
}

const calculateUniformSeriesPresentValue = (periodicInterest: number, periods: number): number => {
	if (periodicInterest === 0) {
    return periods;
  }
  let i = periodicInterest / 100;
  return (1 - Math.pow(1 + i, -periods)) / i;
}

const calculateSinglePaymentPresentValue = (periodicInterest: number, periods: number): number => {
	return Math.pow(1 + periodicInterest / 100, -periods);
}
