import { PaymentMethod } from '../../enums/PaymentMethod';
import { PaymentStep } from '../../enums/PaymentStep';
import { Reference } from '../../enums/Reference';
import { ICalculationTypeOptions } from '../options/ICalculationTypeOptions';
import { ICalculationType } from './ICalculationType';
import { ICalculationTypeField } from './ICalculationTypeField';

export class CalculationType implements ICalculationType {
	id: number = 0;
	description: string = 'Standard';
	domain: string = 'DEFAULT';
	domainDescription: string = '';
	originNr: number = 0;
	crtDate: Date = new Date();
	chgDate: Date|null = null;
	deleted: boolean = false;

	specialPayment: ICalculationTypeField = {
		label: 'Sonderzahlung',
		min: 0,
		max: 100,
		default: 0,
		defaultInPercent: false,
		reference: Reference.NewPurchaseCosts,
		disabled: false,
		hidden: false
	};
	presentValueMargin: ICalculationTypeField = {
		label: 'Marge',
		min: 0,
		max: 100,
		default: 0,
		defaultInPercent: true,
		reference: Reference.NewPurchaseCosts,
		disabled: false,
		hidden: false
	};
	term: ICalculationTypeField = {
		label: 'Laufzeit',
		min: 1,
		max: 120,
		default: 24,
		defaultInPercent: false,
		reference: Reference.None,
		disabled: false,
		hidden: false
	};
	interest: ICalculationTypeField = {
		label: 'Zins',
		min: 0,
		max: 100,
		default: 0,
		defaultInPercent: true,
		reference: Reference.None,
		disabled: false,
		hidden: false
	};
	residualValue: ICalculationTypeField = {
		label: 'Restwert',
		min: 0,
		max: 100,
		default: 0,
		defaultInPercent: false,
		reference: Reference.ListPrice,
		disabled: false,
		hidden: false
	};
	installment: ICalculationTypeField = {
		label: 'Rate',
		min: 0,
		max: 100,
		default: 0,
		defaultInPercent: false,
		reference: Reference.RentAssessmentBase,
		disabled: false,
		hidden: false
	};
	paymentStep = {
		label: 'Zahlweise',
		default: PaymentStep.Monthly,
		disabled: false,
		hidden: false
	};
	paymentMethod = {
		label: 'Zahlmodus',
		default: PaymentMethod.InAdvance,
		disabled: false,
		hidden: false
	}

	rentAssessmentBase: Reference = Reference.NewPurchaseCostsMinusSpecialPayment;

	constructor(options: ICalculationTypeOptions = {}) {
		for (let key in options) {
			this[key] = options[key];
		}
	}
}
