import { Money } from 'ts-money';
import { CalculatorInput } from './CalculatorInput';
import { IMoneyOrPercentageOptions } from '../options/IMoneyOrPercentageOptions';
import { Reference } from '../../enums/Reference';

export class MoneyOrPercentage extends CalculatorInput {
  raw: string;
  rawChanged: boolean;
  value: number;
  money: Money;
  percent: number;
  decimals: number;
  min: number;
  max: number;
  inEuros: boolean;
  dissolveBy: boolean;
  refercence: Reference;

  constructor(options: IMoneyOrPercentageOptions) {
    super(options);
    this.raw = options.raw;
    this.rawChanged = options.rawChanged;
    this.value = options.value;
    this.money = options.money;
    this.percent = options.percent;
    this.decimals = options.decimals;
    this.min = options.min;
    this.max = options.max;
    this.inEuros = options.inEuros;
    this.dissolveBy = options.dissolveBy;
    this.refercence = options.refercence;
  }
}
