// eslint-disable-next-line
import React from 'react';
import { isPlatform, setupConfig } from '@ionic/react';
import ReactDOM from 'react-dom';
// import { defineCustomElements } from '@ionic/pwa-elements/loader';
import App from './App';
// import * as serviceWorker from './serviceWorker';

setupConfig({
	swipeBackEnabled: !isPlatform('ios')
});

ReactDOM.render(<App />, document.getElementById('root'));

// Call the element loader after the app has been rendered the first time
// defineCustomElements(window);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
