import { Currencies, Money } from "ts-money";
import { Field } from "../enums/Field";
import { PaymentMethod } from "../enums/PaymentMethod";
import { PaymentStep } from "../enums/PaymentStep";
import { Reference } from "../enums/Reference";
import { CalculationType } from "../models/calculation/CalculationType";
import { ICalculationTypeField } from "../models/calculation/ICalculationTypeField";
import { ICalculatorInput } from "../models/inputs/ICalculatorInput";
import { ICalculatorInputs } from "../models/inputs/ICalculatorInputs";
import { MoneyOrPercentage } from "../models/inputs/MoneyOrPercentage";
import { Select } from "../models/inputs/Select";
import { Term } from "../models/inputs/Term";
import { roundCommercially } from "./calculationUtils";
import { getPaymentMethodLabel, getPaymentStepLabel } from "./transformUtils";

/**
 * Converts raw string value to a decimal number with a specific precission.
 * 
 * @param {string} rawValue
 * @param {number} decimals Number of decimals
 */
export const convertRawValueToDecimalNumber = (rawValue: string, decimals: number = 2) => {
  if (decimals < 1 || decimals > 10) {
    if (decimals === 0) {
      return parseInt(rawValue);
    } else {
      return 0;
    }
  }
  const decimalHelper: number = Math.pow(10, decimals);
  const decimalNumber = Math.round((parseFloat(rawValue.replace(/\./g, '').replace(/,/g, '.')) + Number.EPSILON) * decimalHelper) / decimalHelper;
  return decimalNumber;
}

/**
 * Calculates percentage from value based in its reference.
 * 
 * @param {ICalculatorInputs} calculatorInputs Calculator inputs
 * @param {Field} id Id of field
 * @param {Reference} rentAssessmentBase Rent assessment base
 * @returns {number} percent
 */
export const calculatePercentageFromValue = (calculatorInputs: ICalculatorInputs, id: Field, rentAssessmentBase: Reference): number => {
  const element: ICalculatorInput = calculatorInputs[id];
  if (element instanceof MoneyOrPercentage) {
    let percent: number = 0;
    let refercence: Reference = element.refercence;
    if (refercence === Reference.RentAssessmentBase) {
      refercence = rentAssessmentBase;
    }
    switch (refercence) {
      case -1:
        percent = roundCommercially(element.value, element.decimals);
        break;
      case 0:
        if (calculatorInputs.newPurchaseCosts.value === 0) {
          percent = 100;
        } else {
          percent = roundCommercially((element.value / calculatorInputs.newPurchaseCosts.value) * 100, element.decimals);
        }
        break;
      case 1:
        if (calculatorInputs.listPrice.value === 0 && calculatorInputs.specialPayment.value === 0) {
          percent = 100;
        } else {
          percent = roundCommercially((element.value / (calculatorInputs.newPurchaseCosts.value - calculatorInputs.specialPayment.value)) * 100, element.decimals);
        }
        break;
      case 4:
        if (calculatorInputs.listPrice.value === 0) {
          percent = 100;
        } else {
          percent = roundCommercially((element.value / calculatorInputs.listPrice.value) * 100, element.decimals);
        }
        break;
      case 6:
        if (calculatorInputs.listPrice.value === 0 && calculatorInputs.discount.value === 0) {
          percent = 100;
        } else {
          percent = roundCommercially((element.value / (calculatorInputs.listPrice.value - calculatorInputs.discount.value)) * 100, element.decimals);
        }
        break;
      case 7:
        if (calculatorInputs.listPrice.value === 0 && calculatorInputs.discount.value === 0 && calculatorInputs.specialPayment.value === 0) {
          percent = 100;
        } else {
          percent = roundCommercially((element.value / (calculatorInputs.listPrice.value - calculatorInputs.discount.value - calculatorInputs.specialPayment.value)) * 100, element.decimals);
        }
        break;
      case 20:
        if (calculatorInputs.newPurchaseCosts.value === 0 && calculatorInputs.discount.value === 0) {
          percent = 100;
        } else {
          percent = roundCommercially((element.value / (calculatorInputs.newPurchaseCosts.value + calculatorInputs.discount.value)) * 100, element.decimals);
        }
        break;
      case 21:
        if (calculatorInputs.listPrice.value === 0 && calculatorInputs.newPurchaseCosts.value === 0) {
          percent = 100;
        } else {
          percent = roundCommercially((element.value / (calculatorInputs.listPrice.value - calculatorInputs.newPurchaseCosts.value)) * 100, element.decimals);
        }
        break;
      default:
        break;
    }
    element.percent = percent;
    return element.percent;
  } else {
    return 0;
  }
}

/**
 * Calculates value from percentage based in its reference.
 * 
 * @param {ICalculatorInputs} calculatorInputs Calculator inputs
 * @param {Field} id Id of field
 * @param {Reference} rentAssessmentBase Rent assessment base
 * @returns {number} value
 */
export const calculateValueFromPercentage = (calculatorInputs: ICalculatorInputs, id: Field, rentAssessmentBase: Reference): number => {
  const element: ICalculatorInput = calculatorInputs[id];
  if (element instanceof MoneyOrPercentage) {
    let value: number = 0;
    let refercence: Reference = element.refercence;
    if (refercence === Reference.RentAssessmentBase) {
      refercence = rentAssessmentBase;
    }
    switch (refercence) {
      case -1:
        value = roundCommercially(element.percent, element.decimals);
        break;
      case 0:
        value = roundCommercially((element.percent / 100) * (calculatorInputs.newPurchaseCosts.value), element.decimals);
        break;
      case 1:
        value = roundCommercially((element.percent / 100) * (calculatorInputs.newPurchaseCosts.value - calculatorInputs.specialPayment.value), element.decimals);
        break;
      case 4:
        value = roundCommercially((element.percent / 100) * (calculatorInputs.listPrice.value), element.decimals);
        break;
      case 6:
        value = roundCommercially((element.percent / 100) * (calculatorInputs.listPrice.value - calculatorInputs.discount.value), element.decimals);
        break;
      case 7:
        value = roundCommercially((element.percent / 100) * (calculatorInputs.listPrice.value - calculatorInputs.discount.value - calculatorInputs.specialPayment.value), element.decimals);
        break;
      case 20:
        value = roundCommercially((element.percent / 100) * (calculatorInputs.newPurchaseCosts.value + calculatorInputs.discount.value), element.decimals);
        break;
      case 21:
        value = roundCommercially((element.percent / 100) * (calculatorInputs.listPrice.value - calculatorInputs.newPurchaseCosts.value), element.decimals);
        break;
      default:
        break;
    }
    element.value = value;
    return element.value;
  } else {
    return 0;
  }
}

/**
 * Updates values and percentages.
 * 
 * @param {ICalculatorInputs} calculatorInputs Calculator inputs 
 * @param {Field} id Id of field
 * @param {Reference} rentAssessmentBase Rent assessment base
 * @returns {ICalculatorInputs} calculatorInputs
 */
export const updateValuesAndPercentages = (calculatorInputs: ICalculatorInputs, id: Field, rentAssessmentBase: Reference): ICalculatorInputs => {
  const element: ICalculatorInput = calculatorInputs[id];
  if (element instanceof MoneyOrPercentage) {
    const listPrice = calculatorInputs.listPrice;
    const newPurchaseCosts = calculatorInputs.newPurchaseCosts;
    const discount = calculatorInputs.discount;
    let fieldsToUpdate: Field[] = [];
    switch (id) {
      case Field.ListPrice:
        // Discount
        if (discount.inEuros) {
          discount.percent = calculatePercentageFromValue(calculatorInputs, Field.Discount, rentAssessmentBase);
        } else {
          discount.value = calculateValueFromPercentage(calculatorInputs, Field.Discount, rentAssessmentBase);
        }

        // New purchase costs
        newPurchaseCosts.value = roundCommercially(listPrice.value - discount.value, newPurchaseCosts.decimals);
        newPurchaseCosts.percent = calculatePercentageFromValue(calculatorInputs, Field.NewPurchaseCosts, rentAssessmentBase);
        newPurchaseCosts.raw = convertNumberToGermanNumberFormat(newPurchaseCosts.value);
        newPurchaseCosts.rawChanged = true;

        // Others
        fieldsToUpdate = [Field.SpecialPayment, Field.ResidualValue, Field.Installment, Field.PresentValueMargin];
        calculatorInputs = updateFieldsInArray(calculatorInputs, rentAssessmentBase, fieldsToUpdate);
        break;
      case Field.Discount:
        // New purchase costs
        newPurchaseCosts.value = roundCommercially(listPrice.value - discount.value, newPurchaseCosts.decimals);
        newPurchaseCosts.percent = calculatePercentageFromValue(calculatorInputs, Field.NewPurchaseCosts, rentAssessmentBase);
        newPurchaseCosts.raw = convertNumberToGermanNumberFormat(newPurchaseCosts.value);
        newPurchaseCosts.rawChanged = true;

        // Others
        fieldsToUpdate = [Field.SpecialPayment, Field.ResidualValue, Field.Installment, Field.PresentValueMargin];
        calculatorInputs = updateFieldsInArray(calculatorInputs, rentAssessmentBase, fieldsToUpdate);
        break;
      case Field.NewPurchaseCosts:
        // List price and discount
        if (discount.inEuros) {
          listPrice.value = roundCommercially(newPurchaseCosts.value + discount.value, listPrice.decimals);
          discount.percent = calculatePercentageFromValue(calculatorInputs, Field.Discount, rentAssessmentBase);
        } else {
          listPrice.value = roundCommercially(newPurchaseCosts.value * (1+ (discount.percent / 100)), listPrice.decimals);
          discount.value = calculateValueFromPercentage(calculatorInputs, Field.Discount, rentAssessmentBase);
        }
        listPrice.percent = calculatePercentageFromValue(calculatorInputs, Field.ListPrice, rentAssessmentBase);
        listPrice.raw = convertNumberToGermanNumberFormat(listPrice.value);
        listPrice.rawChanged = true;

        // Others
        fieldsToUpdate = [Field.SpecialPayment, Field.ResidualValue, Field.Installment, Field.PresentValueMargin];
        calculatorInputs = updateFieldsInArray(calculatorInputs, rentAssessmentBase, fieldsToUpdate);
        break;
      case Field.SpecialPayment:
        // Update others
        fieldsToUpdate = [Field.ResidualValue, Field.Installment, Field.PresentValueMargin];
        calculatorInputs = updateFieldsInArray(calculatorInputs, rentAssessmentBase, fieldsToUpdate);
        break;
      default:
        break;
    }
  }
  return calculatorInputs;
}

/**
 * Updates all fields in given array.
 * 
 * @param {ICalculatorInputs} calculatorInputs Calculator inputs 
 * @param {Reference} rentAssessmentBase Rent assessment base
 * @param {Field[]} fieldsToUpdate Fields to update
 * @returns {ICalculatorInputs} calculatorInputs
 */
export const updateFieldsInArray = (calculatorInputs: ICalculatorInputs, rentAssessmentBase: Reference, fieldsToUpdate: Field[]): ICalculatorInputs => {
  for (let id in fieldsToUpdate) {
    let elementToUpdate: ICalculatorInput = calculatorInputs[id];
    if (elementToUpdate instanceof MoneyOrPercentage) {
      if (elementToUpdate.inEuros) {
        elementToUpdate.percent = calculatePercentageFromValue(calculatorInputs, elementToUpdate.id, rentAssessmentBase);
      } else {
        elementToUpdate.value = calculateValueFromPercentage(calculatorInputs, elementToUpdate.id, rentAssessmentBase);
      }
    }
  }
  return calculatorInputs;
}

/**
 * Calculates percentage of value based on another value with a specific precission.
 * 
 * @param {number} value
 * @param {number} baseValue
 * @param {number} decimals Number of decimals
 */
export const calculatePercentageOfBaseValue2 = (value: number, baseValue: number, decimals: number = 2) => {
  if (decimals < 1 || decimals > 10) {
    return 0;
  }
  const decimalHelper: number = Math.pow(10, decimals);
  const percentage = Math.round(((value / baseValue * 100) + Number.EPSILON) * decimalHelper) / decimalHelper;
  return percentage;
}

/**
 * Calculates value from percentage of base value.
 * 
 * @param {number} percentage 
 * @param {number} baseValue 
 */
export const calculateValueFromPercentage2 = (percentage: number, baseValue: number) => {
  const value = Math.round(((baseValue * (percentage / 100)) + Number.EPSILON) * 100) / 100;
  return value;
}

/**
 * Converts a given number to German number format with a specific precission.
 * 
 * @param {number} inputNumber Number to convert
 * @param {number} decimals Number of decimals
 */
export const convertNumberToGermanNumberFormat = (inputNumber: number = 0, decimals: number = 2) => {
  let inputString = inputNumber.toFixed(decimals);

  const delimiter: string = '.';
  const splitted: any[] = inputString.split('.', 2);
  const decimalPart: any = splitted[1];
  let numberPart: any = parseInt(splitted[0]);
  if (isNaN(numberPart)) {
    return '';
  }
  let minus: string = '';
  if (numberPart < 0 ) {
    minus = '-';
  }

  numberPart = Math.abs(numberPart);
  let number: string = numberPart.toString();
  let a: any[] = [];

  while (number.length > 3) {
    let nn = number.substr(number.length - 3);
    a.unshift(nn);
    number = number.substr(0, number.length - 3);
  }

  if (number.length > 0 ) {
    a.unshift(number);
  }

  number = a.join(delimiter);
  if (typeof decimalPart !== 'undefined' && decimalPart.length < 1) {
    inputString = number;
  } else if (typeof decimalPart !== 'undefined') {
    inputString = number + ',' + decimalPart;
  } else {
    inputString = number;
    for (let i = 0; i < decimals; i++) {
      if (i === 0) {
        inputString += ',';
      }
      inputString += '0';
    }
  }
  inputString = minus + inputString;
  return inputString.toString();
};

/**
 * Proofs the selected calculation type id and returns its belonging
 * calculation type.
 * 
 * @param {Array<CalculationType>} calculationTypes 
 * @param {string} newCalculationTypeId 
 * @returns {CalculationType|undefined} newSelectedCalculationType
 */
export const changeSelectedCalculationType = (calculationTypes: CalculationType[], newCalculationTypeId: number): CalculationType|undefined => {
  const newSelectedCalculationType: CalculationType | undefined = calculationTypes.find(calculationType => calculationType.id === newCalculationTypeId);
  if (newSelectedCalculationType instanceof CalculationType) {
    return newSelectedCalculationType;
  } else {
    return undefined;
  }
}

/**
 * Generates new calculator inputs.
 * 
 * @param {CalculationType} selectedCalculationType 
 * @returns {ICalculatorInputs} calculatorInputs
 */
export const generateCalculatorInputs = (selectedCalculationType: CalculationType): ICalculatorInputs => {
  const calculatorInputs: ICalculatorInputs = {
    listPrice: new MoneyOrPercentage({
      id: Field.ListPrice, label: 'Listenpreis', value: 0, disabled: false, hidden: false, isFocussed: false, raw: '', rawChanged: true, money: new Money(0, Currencies.EUR), percent: 0, decimals: 2, min: 0, max: 100, dissolveBy: false, inEuros: true, refercence: Reference.NewPurchaseCostsPlusDiscount
    }),
    discount: new MoneyOrPercentage({
      id: Field.Discount, label: 'Rabatt', value: 0, disabled: false, hidden: false, isFocussed: false, raw: '', rawChanged: true, money: new Money(0, Currencies.EUR), percent: 0, decimals: 5, min: 0, max: 100, dissolveBy: false, inEuros: true, refercence: Reference.ListPrice
    }),
    specialPayment: generateMoneyOrPercentageFromCalculationType(Field.SpecialPayment, 'Sonderzahlung', selectedCalculationType),
    residualValue: generateMoneyOrPercentageFromCalculationType(Field.ResidualValue, 'Restwert', selectedCalculationType),
    term: new Term({
      id: Field.Term, label: 'Laufzeit', value: selectedCalculationType.term.default, disabled: selectedCalculationType.term.disabled, hidden: selectedCalculationType.term.hidden, isFocussed: false, raw: selectedCalculationType.term.default, rawChanged: true, min: selectedCalculationType.term.min, max: selectedCalculationType.term.max, dissolveBy: false
    }),
    installment: generateMoneyOrPercentageFromCalculationType(Field.Installment, 'Rate', selectedCalculationType),
    newPurchaseCosts: new MoneyOrPercentage({
      id: Field.NewPurchaseCosts, label: 'NAK', value: 0, disabled: false, hidden: false, isFocussed: false, raw: '', rawChanged: true, money: new Money(0, Currencies.EUR), percent: 0, decimals: 2, min: 0, max: 100, dissolveBy: false, inEuros: true, refercence: Reference.ListPriceMinusDiscount
    }),
    presentValueMargin: generateMoneyOrPercentageFromCalculationType(Field.PresentValueMargin, 'Marge', selectedCalculationType),
    interest: generateMoneyOrPercentageFromCalculationType(Field.Interest, 'Zins', selectedCalculationType),
    paymentStep: new Select({
      id: Field.PaymentStep, label: 'Zahlweise', value: selectedCalculationType.paymentStep.default, valueChanged: true, disabled: selectedCalculationType.paymentStep.disabled, hidden: selectedCalculationType.paymentStep.hidden, isFocussed: false, selectOptions: [ 
        { value: PaymentStep.Monthly, label: getPaymentStepLabel(PaymentStep.Monthly) },
        { value: PaymentStep.Quarterly, label: getPaymentStepLabel(PaymentStep.Quarterly) },
        { value: PaymentStep.ThreeTimesAYear, label: getPaymentStepLabel(PaymentStep.ThreeTimesAYear) },
        { value: PaymentStep.HalfYearly, label: getPaymentStepLabel(PaymentStep.HalfYearly) },
        { value: PaymentStep.Yearly, label: getPaymentStepLabel(PaymentStep.Yearly) }
      ]
    }),
    paymentMethod: new Select({
      id: Field.PaymentMethod, label: 'Zahlmodus', value: selectedCalculationType.paymentMethod.default, valueChanged: true, disabled: selectedCalculationType.paymentMethod.disabled, hidden: selectedCalculationType.paymentMethod.hidden, isFocussed: false, selectOptions: [ 
        { value: PaymentMethod.InAdvance, label: getPaymentMethodLabel(PaymentMethod.InAdvance) }, 
        { value: PaymentMethod.InArrears, label: getPaymentMethodLabel(PaymentMethod.InArrears) }
      ]
    })
  };
  return calculatorInputs;
}

/**
 * Generates money or percentage inputs.
 * 
 * @param {Field} field 
 * @param {string} label 
 * @param {CalculationType} selectedCalculationType 
 * @returns {MoneyOrPercentage} moneyOrPercentage
 */
const generateMoneyOrPercentageFromCalculationType = (field: Field, label: string, selectedCalculationType: CalculationType): MoneyOrPercentage => {
  const calculationTypeField: ICalculationTypeField = selectedCalculationType[field];
  let value: number = 0;
  let percent: number = 0;
  let raw: string = '';

  if (!calculationTypeField.defaultInPercent) {
    value = calculationTypeField.default;
    if (value > 0) {
      raw = convertNumberToGermanNumberFormat(value);
    }
  } else {
    percent = calculationTypeField.default;
    value = calculationTypeField.default;
    if (percent > 0) {
      raw = convertNumberToGermanNumberFormat(percent, 3);
    }
  }
  return new MoneyOrPercentage({
    id: field, 
    label: label, 
    value: value, 
    disabled: calculationTypeField.disabled, 
    hidden: calculationTypeField.hidden, 
    isFocussed: false, 
    raw: raw, 
    rawChanged: true, 
    money: Money.fromDecimal(value, Currencies.EUR), 
    percent: percent, 
    decimals: 5, 
    min: calculationTypeField.min, 
    max: calculationTypeField.max, 
    dissolveBy: false, 
    inEuros: !calculationTypeField.defaultInPercent, 
    refercence: calculationTypeField.reference 
  });
}
