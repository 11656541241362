import { DissolveBy } from '../enums/DissolveBy';
import { PaymentMethod } from '../enums/PaymentMethod';
import { Reference } from '../enums/Reference';
import { Calculation } from '../models/calculation/Calculation'
import { CalculationType } from '../models/calculation/CalculationType';
import { ICalculationField } from '../models/calculation/ICalculationField';
import { ICalculationTypeField } from '../models/calculation/ICalculationTypeField';
import { DBCalculation } from '../models/db/DBCalculation'
import { DBCalculationType } from '../models/db/DBCalculationType';
import { CalculationDto } from '../models/dtos/CalculationDto';
import { CalculationTypeDto } from '../models/dtos/CalculationTypeDto';

export const mapDBCalculationToCalculation = (dbCalculation: DBCalculation): Calculation => {
	const calculation = new Calculation({
		id: dbCalculation.id,
		description: dbCalculation.description,
		crtDate: dbCalculation.crtDate,
		chgDate: dbCalculation.chgDate,
		pinned: dbCalculation.pinned,
		deleted: dbCalculation.deleted,
		status: dbCalculation.status,
		presentValue: mapToICalculationField(dbCalculation.presentValue),
		presentValueOrigin: mapToICalculationField(dbCalculation.presentValueOrigin),
		listPrice: mapToICalculationField(dbCalculation.listPrice),
		discount: mapToICalculationField(dbCalculation.discount),
		newPurchaseCosts: mapToICalculationField(dbCalculation.newPurchaseCosts),
		specialPayment: mapToICalculationField(dbCalculation.specialPayment),
		presentValueMargin: mapToICalculationField(dbCalculation.presentValueMargin),
		term: mapToICalculationField(dbCalculation.term),
		interest: mapToICalculationField(dbCalculation.interest),
		residualValue: mapToICalculationField(dbCalculation.residualValue),
		installment: mapToICalculationField(dbCalculation.installment),
		paymentStep: dbCalculation.paymentStep,
		paymentMethod: dbCalculation.paymentMethod,
		annualInterestRateNominal: dbCalculation.annualInterestRateNominal,
		annualInterestRateEffective: dbCalculation.annualInterestRateEffective,
		dissolveBy: dbCalculation.dissolveBy,
		rentAssessmentBase: dbCalculation.rentAssessmentBase,
		calculationTypeId: dbCalculation.calculationTypeId
	})
	return calculation;
}

const mapToICalculationField = (value: number): ICalculationField => {
	const field: ICalculationField = {
		value: value,
		reference: Reference.None
	}
	return field;
}

export const mapCalculationToDBCalculation = (calculation: Calculation): DBCalculation => {
	const dbCalculation = new DBCalculation({
		id: calculation.id,
		description: calculation.description,
		crtDate: calculation.crtDate,
		chgDate: calculation.chgDate,
		pinned: calculation.pinned,
		deleted: calculation.deleted,
		status: calculation.status,
		presentValue: calculation.presentValue.value,
		presentValueOrigin: calculation.presentValueOrigin.value,
		listPrice: calculation.listPrice.value,
		discount: calculation.discount.value,
		newPurchaseCosts: calculation.newPurchaseCosts.value,
		specialPayment: calculation.specialPayment.value,
		presentValueMargin: calculation.presentValueMargin.value,
		term: calculation.term.value,
		interest: calculation.interest.value,
		residualValue: calculation.residualValue.value,
		installment: calculation.installment.value,
		paymentStep: calculation.paymentStep,
		paymentMethod: calculation.paymentMethod,
		annualInterestRateNominal: calculation.annualInterestNominal,
		annualInterestRateEffective: calculation.annualInterestEffective,
		dissolveBy: calculation.dissolveBy,
		rentAssessmentBase: calculation.rentAssessmentBase,
		calculationTypeId: calculation.calculationTypeId
	});
	return dbCalculation;
}

export const mapDBCalculationToCalculationDto = (dbCalcaulation: DBCalculation, calculationTypeDomain: string, calculationTypeNr: number) => {
	const calculationDto = new CalculationDto();
	calculationDto.nr = dbCalcaulation.id;
	calculationDto.description = dbCalcaulation.description;
	calculationDto.status = dbCalcaulation.status;
	calculationDto.presentValue = dbCalcaulation.presentValue;
	calculationDto.presentValueOrigin = dbCalcaulation.presentValueOrigin;
	calculationDto.listPrice = dbCalcaulation.listPrice;
	calculationDto.discount = dbCalcaulation.discount;
	calculationDto.newPurchaseCosts = dbCalcaulation.newPurchaseCosts;
	calculationDto.specialPayment = dbCalcaulation.specialPayment;
	calculationDto.presentValueMargin = dbCalcaulation.presentValueMargin;
	calculationDto.term = dbCalcaulation.term;
	calculationDto.interest = dbCalcaulation.interest;
	calculationDto.residualValue = dbCalcaulation.residualValue;
	calculationDto.installment = dbCalcaulation.installment;
	calculationDto.paymentStep = dbCalcaulation.paymentStep;
	calculationDto.paymentMethod = dbCalcaulation.paymentMethod === PaymentMethod.InAdvance ? true : false;
	calculationDto.dissolveBy = dbCalcaulation.dissolveBy;
	calculationDto.rentAssessmentBase = dbCalcaulation.rentAssessmentBase;
	calculationDto.calculationTypeDomain = calculationTypeDomain;
	calculationDto.calculationTypeNr = calculationTypeNr;
	return calculationDto;
}

export const mapCalculationDtoToDBCalculation = (calculationDto: CalculationDto, calculationTypeId: number) => {
	const dbCalculation = new DBCalculation({
		id: calculationDto.nr,
		description: calculationDto.description,
		status: calculationDto.status,
		presentValue: calculationDto.presentValue,
		presentValueOrigin: calculationDto.presentValueOrigin,
		listPrice: calculationDto.listPrice,
		discount: calculationDto.discount,
		newPurchaseCosts: calculationDto.newPurchaseCosts,
		specialPayment: calculationDto.specialPayment,
		presentValueMargin: calculationDto.presentValueMargin,
		term: calculationDto.term,
		interest: calculationDto.interest,
		residualValue: calculationDto.residualValue,
		installment: calculationDto.installment,
		paymentStep: calculationDto.paymentStep,
		paymentMethod: calculationDto.paymentMethod === true ? PaymentMethod.InAdvance : PaymentMethod.InArrears,
		rentAssessmentBase: calculationDto.rentAssessmentBase,
		calculationTypeId: calculationTypeId
	});
	if (typeof calculationDto.dissolveBy !== 'undefined') {
		switch (calculationDto.dissolveBy) {
			case 'newPurchaseCosts':
				dbCalculation.dissolveBy = DissolveBy.NewPurchaseCosts;
				break;
			case 'specialPayment':
				dbCalculation.dissolveBy = DissolveBy.SpecialPayment;
				break;
			case 'presentValueMargin':
				dbCalculation.dissolveBy = DissolveBy.PresentValueMargin;
				break;
			case 'term':
				dbCalculation.dissolveBy = DissolveBy.Term;
				break;
			case 'interest':
				dbCalculation.dissolveBy = DissolveBy.Interest;
				break;
			case 'residualValue':
				dbCalculation.dissolveBy = DissolveBy.ResidualValue;
				break;
			case 'installment':
				dbCalculation.dissolveBy = DissolveBy.Installment;
				break;
			default:
				break;
		}
	}
	return dbCalculation;
}

export const mapCalculationDtoToCalculation = (calculationDto: CalculationDto, calculationTypeId: number) => {
	return mapDBCalculationToCalculation(mapCalculationDtoToDBCalculation(calculationDto, calculationTypeId));
}

export const mapCalculationToCalculationDto = (calculation: Calculation, calculationTypeDomain: string, calculationTypeNr: number) => {
	return mapDBCalculationToCalculationDto(mapCalculationToDBCalculation(calculation), calculationTypeDomain, calculationTypeNr);
}

export const mapDBCalculationTypeToCalculationType = (dbCalculationType: DBCalculationType): CalculationType => {
	const calculationType = new CalculationType();
	for (let key in dbCalculationType) {
		calculationType[key] = dbCalculationType[key];
	}
	return calculationType;
};

export const mapCalculationTypeToDBCalculationType = (calculationType: CalculationType): DBCalculationType => {
	const dbCalculationType = new DBCalculationType();
	for (let key in calculationType) {
		dbCalculationType[key] = calculationType[key];
	}
	return dbCalculationType;
};

export const mapCalculationTypeDtoToDBCalculationType = (calculationTypeDto: CalculationTypeDto): DBCalculationType => {
	const dbCalculationType = new DBCalculationType();

	dbCalculationType.description = checkUndefinedString(calculationTypeDto.topic, 'Unbenannter Kalkulationstyp');
	dbCalculationType.domain = checkUndefinedString(calculationTypeDto.domain, 'UNKNOWN');
	dbCalculationType.domainDescription = checkUndefinedString(calculationTypeDto.domainTopic, 'Unbenannt');
	dbCalculationType.originNr = checkUndefinedNumber(calculationTypeDto.nr);
	dbCalculationType.originNr = checkUndefinedNumber(calculationTypeDto.nr);
	dbCalculationType.specialPayment = mapToICalculationTypeField(
		dbCalculationType.specialPayment.label,
		calculationTypeDto.specialPaymentMin,
		calculationTypeDto.specialPaymentMax,
		calculationTypeDto.specialPaymentDefault,
		calculationTypeDto.specialPaymentDefaultInPercent,
		calculationTypeDto.specialPaymentReference,
		calculationTypeDto.specialPaymentType
	);
	dbCalculationType.presentValueMargin = mapToICalculationTypeField(
		dbCalculationType.presentValueMargin.label,
		calculationTypeDto.presentValueMarginMin,
		calculationTypeDto.presentValueMarginMax,
		calculationTypeDto.presentValueMarginDefault,
		calculationTypeDto.presentValueMarginDefaultInPercent,
		calculationTypeDto.presentValueMarginReference,
		calculationTypeDto.presentValueMarginType
	);
	dbCalculationType.term = mapToICalculationTypeField(
		dbCalculationType.term.label,
		calculationTypeDto.termMin,
		calculationTypeDto.termMax,
		calculationTypeDto.termDefault,
		dbCalculationType.term.defaultInPercent,
		dbCalculationType.term.reference,
		calculationTypeDto.termType
	);
	dbCalculationType.interest = mapToICalculationTypeField(
		dbCalculationType.interest.label,
		calculationTypeDto.interestMin,
		calculationTypeDto.interestMax,
		calculationTypeDto.interestDefault,
		calculationTypeDto.interestDefaultInPercent,
		calculationTypeDto.interestReference,
		calculationTypeDto.interestType
	);
	dbCalculationType.residualValue = mapToICalculationTypeField(
		dbCalculationType.residualValue.label,
		calculationTypeDto.residualValueMin,
		calculationTypeDto.residualValueMax,
		calculationTypeDto.residualValueDefault,
		calculationTypeDto.residualValueDefaultInPercent,
		calculationTypeDto.residualValueReference,
		calculationTypeDto.residualValueType
	);
	dbCalculationType.installment = mapToICalculationTypeField(
		dbCalculationType.installment.label,
		calculationTypeDto.installmentMin,
		calculationTypeDto.installmentMax,
		calculationTypeDto.installmentDefault,
		calculationTypeDto.installmentDefaultInPercent,
		calculationTypeDto.installmentReference,
		calculationTypeDto.installmentType
	);
	dbCalculationType.paymentStep.default = checkUndefinedNumber(calculationTypeDto.paymentStepDefault);
	dbCalculationType.paymentStep.disabled = (checkUndefinedNumber(calculationTypeDto.paymentStepType) === 0 || checkUndefinedNumber(calculationTypeDto.paymentStepType) === 2);
	dbCalculationType.paymentStep.hidden = checkUndefinedNumber(calculationTypeDto.paymentStepType) === 2;

	dbCalculationType.paymentMethod.default = checkUndefinedBoolean(calculationTypeDto.paymentMethodDefault) ? PaymentMethod.InAdvance : PaymentMethod.InArrears;
	dbCalculationType.paymentMethod.disabled = (checkUndefinedNumber(calculationTypeDto.paymentMethodType) === 0 || checkUndefinedNumber(calculationTypeDto.paymentMethodType) === 2);
	dbCalculationType.paymentMethod.hidden = checkUndefinedNumber(calculationTypeDto.paymentMethodType) === 2;
	return dbCalculationType;
};

const mapToICalculationTypeField = (
	label: string, 
	min: number|null|undefined,
	max: number|null|undefined,
	def: number|null|undefined,
	defInPct: boolean|undefined,
	reference: number|undefined,
	type: number|undefined
): ICalculationTypeField => {
	let disabled: boolean = (type === 0 || type === 2) ? true : false;
	let hidden: boolean = (type === 2) ? true : false;
	const field: ICalculationTypeField = {
		label: label,
		min: checkUndefinedNumber(min, 0),
		max: checkUndefinedNumber(max, 100),
		default: checkUndefinedNumber(def, 0),
		defaultInPercent: checkUndefinedBoolean(defInPct, false),
		reference: checkUndefinedNumber(reference, -1),
		disabled: disabled,
		hidden: hidden
	}
	return field;
}

const checkUndefinedString = (input: string|undefined, fb: string = ''): string => {
	return typeof input !== 'undefined' ? input : fb;
}
const checkUndefinedNumber = (input: number|null|undefined, fb: number = 0): number => {
	if (typeof input === 'undefined' || input === null) {
		return fb;
	} else {
		return input;
	}
}
const checkUndefinedBoolean = (input: boolean|undefined, fb: boolean = false): boolean => {
	return typeof input !== 'undefined' ? input : fb;
}
