export enum Field {
	ListPrice = 'listPrice',
	Discount = 'discount',
	SpecialPayment = 'specialPayment',
	ResidualValue = 'residualValue',
	Term = 'term',
	Installment = 'installment',
	NewPurchaseCosts = 'newPurchaseCosts',
	PresentValueMargin = 'presentValueMargin',
	Interest = 'interest',
	PaymentStep = 'paymentStep',
	PaymentMethod = 'paymentMethod'
}
