import { ICalculatorInput } from './ICalculatorInput';
import { ICalculatorOptions } from '../options/ICalculatorOptions';
import { Field } from '../../enums/Field';

export abstract class CalculatorInput implements ICalculatorInput {
	id: Field;
	label: string;
	disabled: boolean;
	hidden: boolean;
	isFocussed: boolean;
	
	constructor(options: ICalculatorOptions) {
    this.id = options.id;
    this.label = options.label;
    this.disabled = options.disabled;
    this.hidden = options.hidden;
    this.isFocussed = options.isFocussed;
  }
}
