// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonAlert, IonApp, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { calculator, cog, albums, bookmark, shareOutline, addCircleOutline } from 'ionicons/icons';
import Calculator from './pages/Calculator';
import SavedCalculations from './pages/SavedCalculations';
import Settings from './pages/Settings';
import CalculationTypes from './pages/CalculationTypes';
import { CalculationType } from './models/calculation/CalculationType';
import { PaymentStep } from './enums/PaymentStep';
import { PaymentMethod } from './enums/PaymentMethod';
import { Reference } from './enums/Reference';
import CalculationDetail from './pages/CalculationDetail';
import { Calculation } from './models/calculation/Calculation';
import { getCalculationTypeByIdFromDB, getCalculationTypesFromDB, getPendingCalculationsFromDB, storeCalculationTypeInDB, syncCalculationTypes, updateCalculationInDB } from './utils/dbUtils';
import { DB } from './db/DB';
import * as serviceWorker from './serviceWorker';
import SettingDetail from './pages/SettingDetail';
import { useAddToHomescreenPrompt } from './hooks/useAddToHomescreenPrompt';
import { Api } from './api/Api';
import { useStatus } from '@capacitor-community/react-hooks/network';
import { checkIfTokenExpired, IClientInformation, registerClient, isClientRegistered, getClientInformation, getNewAccessToken } from './utils/clientUtils';
import { useGetInfo } from '@capacitor-community/react-hooks/device';
import { DBCalculation } from './models/db/DBCalculation';
import { mapCalculationDtoToCalculation, mapDBCalculationToCalculationDto } from './utils/mapperUtils';
import { CalculationDto } from './models/dtos/CalculationDto';
import { CalculationStatus } from './enums/CalculationStatus';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './App.css';

const App: React.FC = () => {
  
  const [showServiceWorkerReloadToast, setShowServiceWorkerReloadToast] = useState<boolean>(false);
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);

  const { info } = useGetInfo();
  const { networkStatus } = useStatus();
  // eslint-disable-next-line
  const [apiConnected, setApiConnected] = useState<boolean>(false);
  // eslint-disable-next-line
  const [clientRegistered, setClientRegistered] = useState<boolean>(false);

  const [showInstallPromptIos, setShowInstallPromptIos] = useState<boolean>(false);
  const [showInstallPrompt, setShowInstallPrompt] = useState<boolean>(false);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const [debug] = useState<boolean>(false);
  const [debugCalculationTypes] = useState<Array<CalculationType>>([
    new CalculationType({
      id: 0, 
      description: 'Test', 
      domain: 'DEBUG',
      domainDescription: 'Debug',
      term: {
        label: 'Laufzeit',
        min: 1,
        max: 120,
        default: 36,
        defaultInPercent: false,
        reference: Reference.None,
        disabled: true,
        hidden: false
      },
      residualValue: {
        label: 'Restwert',
        min: 0,
        max: 100,
        default: 1000,
        defaultInPercent: false,
        reference: Reference.ListPrice,
        disabled: true,
        hidden: false
      },
      paymentStep: { 
        default: PaymentStep.Yearly,
        disabled: true,
        hidden: false
      },
      paymentMethod: { 
        default: PaymentMethod.InArrears,
        disabled: true,
        hidden: true
      }
    })
  ]);

  const [calculationTypes, setCalculationTypes] = useState<Array<CalculationType>>([
    new CalculationType({
      id: 0, 
      description: 'Standard', 
      domain: 'TEMP',
      domainDescription: ''
    })
  ]);
  const [selectedCalculationType, setSelectedCalculationType] = useState<CalculationType>(calculationTypes[0]);
  const [calculationToLoad, setCalculationToLoad] = useState<Calculation>();
  const [reloadCalculations, setReloadCalculations] = useState<boolean>(false);

  useEffect(() => {
    if (networkStatus?.connected && typeof info?.model !== 'undefined') {
      getPing();
      initClient();
      updateClient();
    }
    // eslint-disable-next-line
  }, [networkStatus, info]);

  useEffect(() => {
    if (networkStatus?.connected) {
      checkForPendingCalculations();
    }
    // eslint-disable-next-line
  }, [networkStatus]);

  const getPing = async () => {
    const api = new Api();
    const response = await api.getPing();
    if (typeof response !== 'undefined') {
      setApiConnected(true);
    } else {
      setApiConnected(false);
    }
  }

  const initClient = async () => {
    const clientId: string|undefined = info?.uuid;
    const topic: string|undefined = info?.model;
    const clientAlreadyRegistered = await isClientRegistered();
    if (!clientAlreadyRegistered) {
      const clientRegistrationSuccessful = await registerClient(clientId, topic)
      setClientRegistered(clientRegistrationSuccessful);
    }
  }

  const updateClient = async () => {
    const clientInformation: IClientInformation|undefined = await getClientInformation();
    if (typeof clientInformation !== 'undefined' && checkIfTokenExpired(clientInformation.accessToken)) {
      await getNewAccessToken(clientInformation);
    }
  }

  const checkForPendingCalculations = async () => {
    const db = new DB();
    const pendingCalculations: Array<DBCalculation> = await getPendingCalculationsFromDB(db);
    if (pendingCalculations.length > 0) {
      const api = new Api();
      const clientInformation: IClientInformation|undefined = await getClientInformation();
      if (typeof clientInformation !== 'undefined') {
        api.token = clientInformation.accessToken;
      }
      const asyncForEach = async (array: Array<any>, callback: any) => {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array);
        }
      }
      await asyncForEach(pendingCalculations, async (pendingCalculation: DBCalculation) => {
        const calculationTypeForPendingCalculation = await getCalculationTypeByIdFromDB(db, pendingCalculation.calculationTypeId);
        if (typeof calculationTypeForPendingCalculation?.id !== 'undefined' && typeof calculationTypeForPendingCalculation?.domain !== 'undefined') {
          const calculationDto: CalculationDto = mapDBCalculationToCalculationDto(pendingCalculation, calculationTypeForPendingCalculation?.domain, calculationTypeForPendingCalculation?.originNr);
          const response = await api.doCalculation(calculationDto);
          if (typeof response !== 'undefined' && response.data && response.status === 200) {
            const calculationDtoFromApi: CalculationDto = response.data;
            const calculationFromApi: Calculation = mapCalculationDtoToCalculation(calculationDtoFromApi, calculationTypeForPendingCalculation.id);
            calculationFromApi.status = CalculationStatus.Finished;
            calculationFromApi.chgDate = new Date();
            await updateCalculationInDB(db, calculationFromApi);
          }
        }
      });
      setReloadCalculations(true);
    }
  }

  useEffect(() => {
    serviceWorker.register({ 
      onUpdate: onServiceWorkerUpdate 
    });
    loadCalculationTypes();

    if ((isPlatform('iphone')|| isPlatform('ipad')) && !isPlatform('pwa')) {
      const dismissDate: string|null = window.localStorage.getItem('install_prompt_dismiss_date');
      const currentMinusOneWeek: number = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
      if (dismissDate === null || (parseInt(dismissDate, 10) < currentMinusOneWeek)) {
        setTimeout(() => {
          setShowInstallPromptIos(true);
        }, 3000);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dismissDate: string|null = window.localStorage.getItem('install-prompt-dismiss-date');
    const currentMinusOneWeek: number = new Date().getTime() - (7 * 24 * 60 * 60 * 1000);
    if (prompt && (dismissDate === null || (parseInt(dismissDate, 10) < currentMinusOneWeek))) {
      setTimeout(() => {
        setShowInstallPrompt(true);
      }, 3000);
    }
  }, [prompt]);

  const onServiceWorkerUpdate = async (registration: ServiceWorkerRegistration) => {
    if (registration === null) {
      const newRegistration = await navigator.serviceWorker.getRegistration();
      if (typeof newRegistration !== 'undefined') {
        setShowServiceWorkerReloadToast(true);
        setWaitingWorker(newRegistration.waiting);
      }
    } else {
      setShowServiceWorkerReloadToast(true);
      setWaitingWorker(registration.waiting);
    }
    
  };

  const reloadPage = () => {
    if (waitingWorker !== null){
      waitingWorker.postMessage('skipWaiting');
    }
    setShowServiceWorkerReloadToast(false);
    window.location.reload();
  };

  const loadCalculationTypes = async () => {
    const db = new DB();

    await syncCalculationTypes(db);

    let defaultCalculationType = new CalculationType({
      id: 0, 
      description: 'Standard', 
      domain: 'DEFAULT',
      domainDescription: ''
    });
    const calculationTypesFromDB: CalculationType[] = await getCalculationTypesFromDB(db);
    if (calculationTypesFromDB.length === 0) {
      await storeCalculationTypeInDB(db, defaultCalculationType);
      calculationTypesFromDB.push(defaultCalculationType);
    }

    if (debug) {
      debugCalculationTypes.forEach(debugCalculationType => {
        calculationTypesFromDB.push(debugCalculationType);
      })
    }

    setCalculationTypes(calculationTypesFromDB);
    const index = calculationTypesFromDB.findIndex(c => c.domain === 'DEFAULT');
    if (index >= 0) {
      defaultCalculationType = calculationTypesFromDB[index];
    }
    setSelectedCalculationType(defaultCalculationType);
  }

  const loadCalculationInCalculator = (calculation: Calculation) => {
    let index = calculationTypes.findIndex(c => c.id === calculation.calculationTypeId);
    if (index === -1) {
      index = calculationTypes.findIndex(c => c.domain === 'DEFAULT');
    }
    setSelectedCalculationType(calculationTypes[index]);
    setCalculationToLoad(calculation);
  }

  const resetLoadCalculationInCalculator = () => {
    setCalculationToLoad(undefined);
  }

  const doReloadCalculations = () => {
    setReloadCalculations(true);
  }

  const resetReloadCalculations = () => {
    setReloadCalculations(false);
  }

  const calculatorProps = {
    selectedCalculationType: selectedCalculationType,
    calculationToLoad,
    resetLoadCalculationInCalculator,
    doReloadCalculations
  };

  const savedCalculationsProps = {
    reloadCalculations,
    resetReloadCalculations
  };

  const calculationDetailProps = {
    calculationTypes,
    loadCalculationInCalculator,
    doReloadCalculations
  };

  const calculationTypesProps = {
    loadCalculationTypes
  };

  const settingsProps = {
    loadCalculationTypes
  };

  const settingDetailProps = {
    loadCalculationTypes
  };

  return (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          
          <Route exact={true} 
            path="/:tab(calculator)"
            render={() => {
              return <Calculator  {...calculatorProps} />;
            }} />
          <Route exact={true} 
            path="/:tab(calculations)"
            render={() => {
              return <SavedCalculations  {...savedCalculationsProps} />;
            }} />
          <Route exact={true} 
            path="/:tab(calculations)/:id"
            render={(routeProps) => {
              return <CalculationDetail  {...calculationDetailProps} {...routeProps} />;
            }} />
          <Route exact={true} 
            path="/:tab(calculation-types)" 
            render={() => {
              return <CalculationTypes  {...calculationTypesProps} />;
            }} />
          <Route exact={true} 
            path="/:tab(settings)" 
            render={() => {
              return <Settings  {...settingsProps} />;
            }} />
          <Route exact={true} 
            path="/:tab(settings)/:id"
            render={(routeProps) => {
              return <SettingDetail  {...settingDetailProps} {...routeProps} />;
            }} />
          <Route exact={true} 
            path="/" 
            render={() => <Redirect  to="/calculator" />} />

        </IonRouterOutlet>
        <IonTabBar class="tab-bar" slot="bottom">
          <IonTabButton tab="calculator" href="/calculator">
            <IonIcon icon={calculator} />
            <IonLabel>Kalkulator</IonLabel>
          </IonTabButton>
          <IonTabButton tab="calculations" href="/calculations">
            <IonIcon icon={bookmark} />
            <IonLabel>Gespeichert</IonLabel>
          </IonTabButton>
          <IonTabButton tab="calculation-types" href="/calculation-types">
            <IonIcon icon={albums} />
            <IonLabel>Kalkulationstypen</IonLabel>
          </IonTabButton>
          <IonTabButton tab="settings" href="/settings">
            <IonIcon icon={cog} />
            <IonLabel>Einstellungen</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>

    <IonToast
      isOpen={showServiceWorkerReloadToast}
      onDidDismiss={() => setShowServiceWorkerReloadToast(false)}
      message="Neue Version verfügbar"
      cssClass="toast-service-worker-update"
      position="bottom"
      buttons={[
        {
          side: 'end',
          text: 'Aktualisieren',
          handler: () => {
            reloadPage();
          }
        }
      ]}
    />

    <IonModal 
      isOpen={showInstallPromptIos}
      cssClass="modal-install-ios"
      swipeToClose={true}
      backdropDismiss={true}
      onDidDismiss={() => {
        setShowInstallPromptIos(false);
        window.localStorage.setItem('install-prompt-dismiss-date', new Date().getTime().toString());
      }}>
        <IonHeader translucent>
            <IonToolbar>
              <IonTitle>Installieren</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => {
                  setShowInstallPromptIos(false);
                }}>Schließen</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList class="ion-margin-top">
              <IonItem lines="none">
                <IonLabel color="medium" class="ion-text-wrap">Installieren Sie den LeasOne Kalkulator und greifen Sie auf zusätzliche Funktionen zu.</IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonIcon slot="end" icon={shareOutline} color="primary"></IonIcon>
                <IonLabel>Teilen-Button klicken</IonLabel>
              </IonItem>
              <IonItem lines="none">
                <IonIcon slot="end" icon={addCircleOutline}></IonIcon>
                <IonLabel>Zum Home-Bildschirm</IonLabel>
              </IonItem>
            </IonList>
          </IonContent>
    </IonModal>

    <IonAlert
      isOpen={showInstallPrompt}
      onDidDismiss={() => {
        setShowInstallPrompt(false);
        window.localStorage.setItem('install-prompt-dismiss-date', new Date().getTime().toString());
      }}
      cssClass=''
      header="LeasOne Kalkulator installieren"
      message="Installieren Sie den LeasOne Kalkulator und greifen Sie auf zusätzliche Funktionen zu."
      buttons={[
        {
          text: 'Nicht jetzt',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Installieren',
          handler: () => {
            promptToInstall();
          }
        }
      ]}
    />

  </IonApp>
  );
};

export default App;
