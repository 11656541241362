// eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';
import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSegment, IonSegmentButton, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { arrowBack, chevronBack } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router';
import { DB } from '../db/DB';
import { CalculationType } from '../models/calculation/CalculationType';
import { convertNumberToGermanNumberFormat, convertRawValueToDecimalNumber } from '../utils/calculatorUtils';
import { getDefaultCalculationTypeFromDB, updateCalculationTypeInDB } from '../utils/dbUtils';
import { mapDBCalculationTypeToCalculationType } from '../utils/mapperUtils';
import './SettingDetail.css';

interface ISettingDetailProps extends RouteComponentProps<{
	id: string;
}> {
	loadCalculationTypes: () => void;
}

const SettingDetail: React.FC<ISettingDetailProps> = (props) => {

	const [calculationType, setCalculationType] = useState<CalculationType>(new CalculationType());
	const [id] = useState<string>(props.match.params.id);
	const [label, setLabel] = useState<string>('');
	const [defaultInPercent, setDefaultInPercent] = useState<boolean>(false);
	const [unitHidden, setUnitHidden] = useState<boolean>(false);
	const [unitDisabled, setUnitDisabled] = useState<boolean>(false);
	const [raw, setRaw] = useState<string>('0,00');
	const [placeholder, setPlaceholder] = useState<string>('0,00');
	const [valueUnit, setValueUnit] = useState<string>('€');
	const [inputMode, setInputMode] = useState<any>('decimal');
	const [helpText, setHelpText] = useState<string>('');

	const inputElement = useRef<any>(null);

	useEffect(() => {
		if (typeof props.match.params.id !== 'undefined' && typeof calculationType[id] !== 'undefined' && 'label' in calculationType[id]) {
			const db = new DB();
			getCalculationType(db);
		} else {
			props.history.push('/settings');
		}
		// eslint-disable-next-line
	}, []);

	const getCalculationType = async (db: DB) => {
		const calculationTypeFromDB = await getDefaultCalculationTypeFromDB(db);
		if (typeof calculationTypeFromDB !== 'undefined') {
			const mappedCalculationType = mapDBCalculationTypeToCalculationType(calculationTypeFromDB);
			setCalculationType(mappedCalculationType);

			if (id in calculationTypeFromDB && 'label' in calculationTypeFromDB[id]) {
				setLabel(calculationTypeFromDB[id].label);
				setDefaultInPercent(calculationTypeFromDB[id].defaultInPercent);
				
				if (typeof calculationTypeFromDB[id].default === 'number') {
					if (id === 'interest') {
						setRaw(convertNumberToGermanNumberFormat(calculationTypeFromDB[id].default, 3));
						setPlaceholder('0,000');
						setValueUnit('%');
						setUnitDisabled(true);
					} else if (id === 'term') {
						setRaw(convertNumberToGermanNumberFormat(calculationTypeFromDB[id].default, 0));
						setPlaceholder('24');
						setUnitHidden(true);
						setValueUnit('Monat(e)');
						setInputMode('numeric');
						setHelpText('Der Standardwert für die Laufzeit muss zwischen ' + calculationTypeFromDB.term.min + ' und ' + calculationTypeFromDB.term.max + ' liegen.');
					} else {
						setRaw(convertNumberToGermanNumberFormat(calculationTypeFromDB[id].default));
						setPlaceholder('0,00');
						setHelpText('Es kann nur ein Standardwert festgelegt werden, wenn die Einheit Euro ist.');
					}
				}
			}
		} else {
			props.history.push('/settings');
		}
	}

	const updateCalculationType = async (calculationTypeToUpdate: CalculationType) => {
		if (calculationTypeToUpdate.domain === 'DEFAULT') {
			const db = new DB();
			calculationTypeToUpdate.chgDate = new Date();
			await updateCalculationTypeInDB(db, calculationTypeToUpdate);
			setCalculationType(calculationTypeToUpdate);
			props.loadCalculationTypes();
		}
	}

	const onUnitChange = async (e: CustomEvent) => {
		e.preventDefault();
		const value: string | undefined = e.detail.value;
		if (typeof value !== 'undefined' && 'defaultInPercent' in calculationType[id]) {
			if (value === 'euro') {
				calculationType[id].defaultInPercent = false;
				setDefaultInPercent(false);
			} else if (value === 'percent') {
				setDefaultInPercent(true);
				calculationType[id].defaultInPercent = true;
				if (id !== 'interest') {
					calculationType[id].default = 0;
					setRaw(convertNumberToGermanNumberFormat(0, 2))
				}
			}
			await updateCalculationType(calculationType);
		}
	}

	const onChange = (e: CustomEvent) => {
		e.preventDefault();
		const value: any = e.detail.value;
		if (id === 'term') {
			if (!isNaN(value)) {
				let numberValue: number;
				if (typeof value === 'string') {
					numberValue = parseInt(value);
				} else {
					numberValue = value;
				}
				if (numberValue < calculationType.term.min) {
					setRaw(calculationType.term.min.toString());
				} else if (numberValue > calculationType.term.max) {
					setRaw(calculationType.term.max.toString());
				} else {
					setRaw(value);
				}
			}
		} else {
			if (!isNaN(value.replace(/\./g, '').replace(/,/g, '.'))) {
				setRaw(value);
			}
		}
	}

	const onBlur = async (raw: string) => {
		let value: number = 0;
		if (id === 'term') {
			value = convertRawValueToDecimalNumber(raw, 0);
		} else if (id === 'interest') {
			value = convertRawValueToDecimalNumber(raw, 3);
			setRaw(convertNumberToGermanNumberFormat(value, 3));
		} else {
			value = convertRawValueToDecimalNumber(raw, 2);
			setRaw(convertNumberToGermanNumberFormat(value, 2));
		}
		calculationType[id].default = value;
		await updateCalculationType(calculationType);
	}

	const onInput = (value: any) => {
		if (typeof value !== 'undefined' && value !== null) {
			const valueParts = value.toString().split('.');
			if (isPlatform('android') && valueParts.length === 2 && !value.toString().includes(',') && valueParts[valueParts.length - 1].length < 3) {
				setRaw(value.toString().replace(/\./g, ','));
			}
		}
	}

	return (
		<IonPage>
      <IonHeader>
        <IonToolbar>
					<IonButtons slot="start">
						<IonButton routerLink="/settings" routerDirection="back">
              <IonIcon slot="icon-only" ios={chevronBack} md={arrowBack}/>
            </IonButton>
					</IonButtons>
          <IonTitle>{label}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false} class={isPlatform('desktop') ? 'content-desktop' : ''}>
				<IonGrid class="ion-no-padding ion-padding-vertical">
          
          <IonRow class="ion-align-items-start ion-justify-content-start ion-padding-top">
            <IonCol class="" size="12" sizeMd="6" hidden={unitHidden}>
							<IonItem lines="inset">
                <IonLabel>Einheit</IonLabel>
								<IonSegment class="segment-setting"
									value={defaultInPercent ? 'percent' : 'euro'}
									disabled={unitDisabled}
									onIonChange={async (e) => await onUnitChange(e)}>
									<IonSegmentButton class="segment-setting-button" value="euro">
										<IonLabel>€</IonLabel>
									</IonSegmentButton>
									<IonSegmentButton class="segment-setting-button" value="percent">
										<IonLabel>%</IonLabel>
									</IonSegmentButton>
								</IonSegment>
							</IonItem>
						</IonCol>
					</IonRow>
					<IonRow class="ion-align-items-start ion-justify-content-start">
						<IonCol class="" size="12" sizeMd="6">
							<IonItem lines="inset">
                <IonLabel position="fixed">Standardwert</IonLabel>
								<IonInput ref={inputElement} class="ion-text-end"
									name="default-value"
									id="default-value"
									disabled={defaultInPercent && id !== 'interest'}
									value={raw}
									placeholder={placeholder}
									inputmode={inputMode}
									onIonChange={e => onChange(e)}
									onIonBlur={() => onBlur(raw)}
									onInput={e => onInput(e.currentTarget.value)}
								></IonInput>
								<IonLabel slot="end" class="">{valueUnit}</IonLabel>
							</IonItem>
						</IonCol>
					</IonRow>
					<IonRow class="ion-align-items-start ion-justify-content-start">
						<IonCol class="" size="12" sizeMd="12">
							<IonItem lines="none">
            		<IonLabel color="medium" class="ion-text-wrap">{helpText}</IonLabel>
          		</IonItem>
						</IonCol>
					</IonRow>

				</IonGrid>

			</IonContent>
    </IonPage>
	);
}

export default SettingDetail;
