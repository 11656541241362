// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { IonContent, IonFooter, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonText, IonTitle, IonToolbar, isPlatform } from '@ionic/react';
import { version } from '../../package.json';
import './Settings.css';
import { useStatus } from '@capacitor-community/react-hooks/network';
import { useGetInfo } from '@capacitor-community/react-hooks/device';

interface ISettingsProps {
  loadCalculationTypes: () => void;
}

const Settings: React.FC<ISettingsProps> = (props) => {

  const [appVersion] = useState<string>(version);

  const { networkStatus } = useStatus();
  const { info } = useGetInfo();

  useEffect(() => {

  }, []);

  const getDeviceNaturalString = (operatingSystem: string|undefined, model: string|undefined): string => {
    switch (operatingSystem) {
      case 'ios':
        return 'iOS ';
      case 'mac':
        return 'macOS ';
      case 'android':
        return '';
      case 'unknown':
        return 'Unbekannt ';
      case undefined:
        return 'Unbekannt ';
      default:
        return operatingSystem.charAt(0).toUpperCase() + operatingSystem.slice(1) + ' ';
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Einstellungen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen={false}>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle color="dark" size="large">Einstellungen</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonList class={isPlatform('desktop') ? 'ion-padding-top list-desktop' : 'ion-padding-top'}>
          <IonItem detail={true} key="settings-1"
          routerLink={'/settings/specialPayment'}>
            <IonLabel>Sonderzahlung</IonLabel>
            <div tabIndex={1}></div>
          </IonItem>
          <IonItem detail={true} key="settings-2"
          routerLink={'/settings/residualValue'}>
            <IonLabel>Restwert</IonLabel>
            <div tabIndex={2}></div>
          </IonItem>
          <IonItem detail={true} key="settings-3"
          routerLink={'/settings/term'}>
            <IonLabel>Laufzeit</IonLabel>
            <div tabIndex={3}></div>
          </IonItem>
          <IonItem detail={true} key="settings-4"
          routerLink={'/settings/installment'}>
            <IonLabel>Rate</IonLabel>
            <div tabIndex={4}></div>
          </IonItem>
          <IonItem detail={true} key="settings-5"
          routerLink={'/settings/presentValueMargin'}>
            <IonLabel>Marge</IonLabel>
            <div tabIndex={5}></div>
          </IonItem>
          <IonItem detail={true} key="settings-6"
          routerLink={'/settings/interest'}>
            <IonLabel>Zins</IonLabel>
            <div tabIndex={6}></div>
          </IonItem>
          <IonItem hidden={true} detail={true} key="settings-7"
          routerLink={'/settings/paymentStep'}>
            <IonLabel>Zahlweise</IonLabel>
            <div tabIndex={7}></div>
          </IonItem>
          <IonItem hidden={true} detail={true} key="settings-8"
          routerLink={'/settings/paymentMethod'}>
            <IonLabel>Zahlmodus</IonLabel>
            <div tabIndex={8}></div>
          </IonItem>
          
          <IonItem lines="none">
            <IonLabel color="medium" class="ion-text-wrap">Hier können Sie für Einstellungen für den Standard-Kalkulationstyp vornehmen.</IonLabel>
          </IonItem>

          <IonListHeader class="ion-margin-top">
            <IonText>
              Informationen
            </IonText>
          </IonListHeader>
          <IonItem lines="none">
              <IonText color="medium" class="">
                App-Version: {appVersion}
              </IonText>
          </IonItem>
          <IonItem lines="none">
              <IonText color="medium" class="">
              © {new Date().getFullYear()} LeasOne
              </IonText>
          </IonItem>
          <IonItem lines="none" hidden>
              <IonText color="medium" class="">
              {info?.model}
              </IonText>
          </IonItem>
          <IonItem lines="none" hidden>
              <IonText color="medium" class="">
              {info?.uuid}
              </IonText>
          </IonItem>
        </IonList>
      </IonContent>

      <IonFooter class="ion-padding-bottom ion-hide">

        <IonItem lines="none">
          <IonLabel class="ion-text-center" color="medium">App-Version: {appVersion} </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonLabel class="ion-text-center" color="medium">© {new Date().getFullYear()} LeasOne</IonLabel>
        </IonItem>

        <IonItem class="online-status-item ion-text-center ion-hide" lines="none">
          <IonLabel>Status: {networkStatus?.connected ? 'online' : 'offline'}</IonLabel>
        </IonItem>
        <IonItem class="info-item ion-text-center ion-hide" lines="none">
          <IonLabel>Info: {info?.model} ({getDeviceNaturalString(info?.operatingSystem, info?.model)}{info?.osVersion})</IonLabel>
        </IonItem>
        <IonItem class="info-item ion-text-center ion-hide" lines="none">
          <IonLabel>UUID: {info?.uuid}</IonLabel>
        </IonItem>
        <IonItem class="info-item ion-text-center ion-hide" lines="none">
          <IonLabel>Modus: {isPlatform('pwa') ? 'PWA' : 'Browser'}</IonLabel>
        </IonItem>
        <IonItem class="version-item ion-padding-bottom ion-text-center ion-hide" lines="none">
          <IonLabel>App-Version: {appVersion}</IonLabel>
        </IonItem>
      </IonFooter>
    </IonPage>
  );
};

export default Settings;
